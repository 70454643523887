import React, { useMemo, useRef } from 'react';
import { useElementSize } from '@hotelplan/libs.hooks-dom';

export default function useMapAutoSize() {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapSize = useElementSize(mapContainerRef);

  return useMemo(() => {
    const styles: React.CSSProperties = {
      position: 'absolute',
      width: `${mapSize.w}px`,
      height: `${mapSize.h}px`,
    };

    return {
      mapContainerRef,
      mapProviderProps: {
        styles,
      },
    };
  }, [mapSize.h, mapSize.w]);
}
