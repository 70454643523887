import React, { useMemo } from 'react';
import styled from 'styled-components';
import { RadioButton } from '@hotelplan/components.common.buttons';
import { RadioGroupInput } from '@hotelplan/components.common.radio-group';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import {
  FilterOptionContent,
  FilterOptionWrapper,
} from 'components/domain/filters/filter-option';
import { FilterOptions as Options } from 'components/domain/filters/Filters.constants';
import { FiltersRadioControl } from 'components/domain/filters/Filters.styles';
import type { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import { useLoadCount } from 'components/domain/filters/search-filter-input/blocks/useLoadCount';

const RadioGroupWrapper = styled(RadioGroupInput).attrs({
  testId: 'stopOvers',
  name: 'stopOvers',
})(
  sx2CssThemeFn({
    m: 0,
    py: [3, '11px'],
  })
);

const FlightStops: React.FC<TComponentProps> = ({
  values,
  countLoaders,
  onChange,
  options,
  inView,
  loadWhenInView,
}: TComponentProps) => {
  const { flightStopOver } = options;
  const { loading, counts } = useLoadCount({
    useRequest: countLoaders.flightStopOver,
    inView,
    loadWhenInView,
  });

  const items = useMemo(
    () =>
      (flightStopOver || []).map(option => {
        const count =
          counts?.find(countData => countData.id === option.id)?.count ?? 0;

        return {
          content: (
            <FilterOptionContent
              uid="flightsStops"
              count={count}
              text={option.caption}
              disabled={!count}
              loading={loading}
            />
          ),
          value: option.id,
          disabled: !count,
        };
      }),
    [flightStopOver, counts, loading]
  );

  return (
    <RadioGroupWrapper
      value={values.flightStopOver || ''}
      options={items}
      onChange={nextStopOver => {
        onChange({
          ...values,
          flightStopOver: nextStopOver,
          prevChangedFilter: Options.flightStopOver,
        });
      }}
      renderCustomOption={option => {
        return (
          <FilterOptionWrapper active={option.checked}>
            <RadioButton
              {...option}
              renderCustomControl={() => (
                <FiltersRadioControl
                  className="customCheckboxInput radio-control"
                  checked={option.checked}
                  disabled={option.disabled}
                />
              )}
            />
          </FilterOptionWrapper>
        );
      }}
    />
  );
};

export default FlightStops;
