import type { CSSObject } from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const controlStyles = sx2CssThemeFn({
  position: 'absolute',
  background: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '10px',
  height: '23px',
  color: 'white',
  fontSize: 1,
});

export const mapFormWrapperStyles = sx2CssThemeFn({
  flex: 1,
  width: '100%',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  height: ['100%', 'auto'],
  '.gm-style-mtc': {
    '& *:first-child': {
      display: 'none',
    },
  },
  margin: [null, '0 -15px'],
});

export const mapContainerStyles: CSSObject = {
  flex: '1 1 auto',
  background: 'bgLightGrey',
  position: 'relative',
};

export const mapContainerWrapperStyles = sx2CssThemeFn({
  width: '100%',
  padding: [null, '0 15px'],
  flexDirection: 'column',
});

export const mapCardsContainerStyles: CSSObject = {
  flex: '0 0 auto',
  maxHeight: '45%',
  overflowY: 'visible',
};
