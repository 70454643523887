import { useTranslation } from 'next-i18next';
import React from 'react';
import MapButtonDesktop from 'components/domain/mapAccess/MapButtonDesktop';

interface IMapAccessButtonProps {
  onOpen(): void;
}

const MapAccessButton: React.FC<IMapAccessButtonProps> = props => {
  const { onOpen } = props;
  const [t] = useTranslation('common');

  return <MapButtonDesktop onClick={onOpen}>{t('mapButton')}</MapButtonDesktop>;
};

export default MapAccessButton;
