import { useTranslation } from 'next-i18next';
import React from 'react';
import styled, { CSSObject } from 'styled-components';
import { Image } from '@hotelplan/components.common.image';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { controlStyles } from './Map.styles';

interface IMapItemsCountControlProps {
  loading?: boolean;
  count: number;
}

const loaderAndCounterStyles: CSSObject = {
  bottom: '10px',
  left: '50%',
  transform: 'translateX(-50%)',
};

const MapItemsImage = styled(Image)(controlStyles, loaderAndCounterStyles, {
  width: '50px',
});

const MapItemsCounter = styled.div(
  controlStyles,
  loaderAndCounterStyles,
  sx2CssThemeFn({
    px: 2,
  })
);

const MapItemsCountControl: React.FC<IMapItemsCountControlProps> = props => {
  const { loading = false, count } = props;
  const [t] = useTranslation('map');

  return loading ? (
    <MapItemsImage
      resized={[]}
      src="/images/spinner-button.svg"
      alt={t('common:loading')}
    />
  ) : (
    <MapItemsCounter>{t('map:map.objects.count', { count })}</MapItemsCounter>
  );
};

export default MapItemsCountControl;
