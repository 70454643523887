import debounce from 'lodash/debounce';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@hotelplan/components.common.checkbox';
import { formatTestId } from '@hotelplan/libs.utils';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import {
  FilterOptionContent,
  FilterOptionWrapper,
} from 'components/domain/filters/filter-option';
import type { IFilterCount } from 'components/domain/filters/Filters.types';
import { filtersComponentPropsAreEqual } from 'components/domain/filters/Filters.utils';
import { FiltersCheckboxControl } from './search-filter-input/SearchFilterInput.styles';

export interface IFilterCheckBoxGroupProps {
  loading?: boolean;
  counts?: IFilterCount[];
  groupId: string;
  value: string[];
  options?: Array<{ id: string; caption: string }>;
  onChange(nextValue: string[]): void;
  showCount?: boolean;
}

const FilterCheckBoxGroupWrapper = styled.div(
  sx2CssThemeFn({
    py: [3, '11px'],
  })
);

const FilterCheckBoxGroup: React.FC<IFilterCheckBoxGroupProps> = props => {
  const {
    groupId,
    value: ids,
    options = [],
    counts,
    onChange,
    loading: inputLoading,
    showCount,
  } = props;

  const loading = inputLoading || typeof counts !== 'object';

  const changeHandler = useCallback(
    (nextValue: string) => {
      const nextIds = ids.includes(nextValue)
        ? ids.filter(value => value !== nextValue)
        : [...ids, nextValue];
      onChange(nextIds);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, ids.length]
  );

  return (
    <FilterCheckBoxGroupWrapper>
      {options.map((option, i) => {
        const count =
          counts?.find(productCount => productCount.id === option.id)?.count ??
          0;
        const checked = ids.includes(option.id);

        // Due to `HPWEBN-641` checked options should not be disabled
        // until user de-select them by himself
        const disabled = !checked && count === 0;

        return (
          <FilterOptionWrapper active={checked} key={option.id}>
            <Checkbox
              testId={formatTestId(`${option.id}`)}
              name={groupId}
              value={option.id}
              disabled={disabled}
              onChange={debounce(changeHandler, 100)}
              checked={checked}
              content={
                <FilterOptionContent
                  uid={`checkboxGroup_${i}`}
                  loading={loading}
                  text={option.caption}
                  count={count}
                  disabled={disabled}
                  showCount={showCount}
                />
              }
              renderCustomControl={() => (
                <FiltersCheckboxControl
                  disabled={disabled}
                  className="customCheckboxInput"
                />
              )}
            />
          </FilterOptionWrapper>
        );
      })}
    </FilterCheckBoxGroupWrapper>
  );
};

export default React.memo(FilterCheckBoxGroup, filtersComponentPropsAreEqual);
