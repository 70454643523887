import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { DoubleRangeInput } from '@hotelplan/components.common.double-range';
import { TextLineSkeleton } from '@hotelplan/components.common.text';

export interface IFlightStopoverDurationRangeInputProps {
  testId?: string;
  minDurationSelected: string | null;
  maxDurationSelected: string | null;
  disabled?: boolean;
  onChange: (minSelected: number, maxSelected: number) => void;
  loading?: boolean;
  maxDuration?: string;
  minDuration?: string;
}

const convertMinutesToHm = (
  minutes: number | string
): { h: number; m: number } => {
  const h = Math.floor(+minutes / 60);
  const m = Math.round(+minutes % 60);

  return { h, m };
};

export const convertMinutesToDurationHours = (
  minutes: number | string
): string => {
  const { h, m } = convertMinutesToHm(minutes);
  const mStr = `0${m}`.slice(-2) + ('m' as const);
  return `${h}h${m === 0 ? '' : mStr}`;
};

export const getFlightStopoverDurationOptions = (
  from: string,
  to: string
): { caption: string; value: number }[] => {
  const options: { caption: string; value: number }[] = [];

  const fromInMinutes = parseInt(from, 10);
  const toInMinutes = parseInt(to, 10);

  // NOTE: The leg in minutes equals 30 min.
  for (let i = fromInMinutes; i < toInMinutes; i += 30) {
    options.push({
      caption: convertMinutesToDurationHours(i),
      value: i,
    });
  }

  options.push({
    caption: convertMinutesToDurationHours(toInMinutes),
    value: toInMinutes,
  });

  return options;
};

const FlightStopoverDurationFilterWrapper = styled.div(
  ({ theme: { space, media, fontSizes, colors } }) => ({
    padding: space[3],
    '.flight-stop-over-duration-text': {
      fontSize: fontSizes[1],
      color: colors.secondary,
      [media.tablet]: {
        fontSize: fontSizes[2],
      },
    },
  })
);

const FlightStopoverDurationFilter: React.FC<IFlightStopoverDurationRangeInputProps> = props => {
  const {
    minDurationSelected,
    maxDurationSelected,
    disabled,
    loading,
    maxDuration,
    minDuration,
    onChange,
    testId = 'flightStopoverDurationInput',
  } = props;

  const [t] = useTranslation('filters');

  const noStops = !minDuration && !maxDuration;
  const noRange = minDuration === maxDuration && !noStops;

  const durationOptions =
    minDuration && maxDuration
      ? getFlightStopoverDurationOptions(
          minDurationSelected !== null && +minDurationSelected < +minDuration
            ? minDurationSelected
            : minDuration,
          maxDurationSelected !== null && +maxDurationSelected > +maxDuration
            ? maxDurationSelected
            : maxDuration
        )
      : [];

  const minValue =
    minDurationSelected !== null &&
    durationOptions.find(
      option => option.value === parseInt(minDurationSelected, 10)
    )
      ? parseInt(minDurationSelected, 10)
      : durationOptions[0]?.value;

  const maxValue =
    maxDurationSelected !== null &&
    durationOptions.find(
      option => option.value === parseInt(maxDurationSelected, 10)
    )
      ? parseInt(maxDurationSelected, 10)
      : durationOptions[durationOptions.length - 1]?.value;

  const tooltipTemplate = (duration: number) => {
    return (
      durationOptions.find(option => option.value === duration)?.caption || ''
    );
  };

  function onChangeHandler(next: number[]) {
    onChange(next[0], next[1]);
  }

  return (
    <FlightStopoverDurationFilterWrapper>
      {loading ? (
        <TextLineSkeleton width={100} />
      ) : (
        <>
          {(noStops || noRange) && (
            <p className="flight-stop-over-duration-text">
              {t('flightStopOverDuration.noStops')}
            </p>
          )}
          {!noStops && !noRange && (
            <DoubleRangeInput<number>
              testId={testId}
              disabled={disabled}
              options={durationOptions}
              values={[minValue, maxValue]}
              onChange={onChangeHandler}
              tooltipTemplate={tooltipTemplate}
            />
          )}
        </>
      )}
    </FlightStopoverDurationFilterWrapper>
  );
};

export default FlightStopoverDurationFilter;
