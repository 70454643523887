import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { MapPin, IMapPinProps } from '@hotelplan/components.common.map-pin';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { SrlMapProductPinFragment } from 'graphql/srlMap/SRLMapFragments.generated';

type Product = SrlMapProductPinFragment;

interface ISRLMapProductPinProps
  extends Omit<IMapPinProps<Product>, 'coordinates' | 'object'> {
  product: Product;
}

const ProductPinContent = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const TopProductIcon = styled(Icon).attrs({ size: 'lg', name: 'top' })(
  sx2CssThemeFn({
    display: 'inline-block',
    pr: 2,
    my: -4,
  })
);

const ProductPrice = styled.div({
  whiteSpace: 'nowrap',
});

const SRLMapProductPin: React.FC<ISRLMapProductPinProps> = props => {
  const { product, ...mapPinProps } = props;
  const [t] = useTranslation('results');
  const isTop = product.top;

  return (
    <MapPin<Product>
      coordinates={product.coordinates}
      object={product}
      {...mapPinProps}
    >
      <ProductPinContent>
        {isTop && <TopProductIcon />}
        <ProductPrice>
          {t('results:price.from', {
            price: `${product.price.currency} ${product.price.amount}`,
          })}
        </ProductPrice>
      </ProductPinContent>
    </MapPin>
  );
};

export default SRLMapProductPin;
