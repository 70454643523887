import React from 'react';
import { FilterOptions as Options } from 'components/domain/filters/Filters.constants';
import FlightAirlinesFilter from 'components/domain/filters/search-filter-input/blocks/airlines/FlightAirlinesFilter';
import type { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import { useLoadCount } from 'components/domain/filters/search-filter-input/blocks/useLoadCount';

const Airlines: React.FC<TComponentProps> = ({
  values,
  countLoaders,
  onChange,
  inView,
  loadWhenInView,
}: TComponentProps) => {
  const { loading, options } = useLoadCount({
    useRequest: countLoaders.flightAirlines,
    inView,
    loadWhenInView,
  });

  return (
    <FlightAirlinesFilter
      loading={loading}
      options={options}
      value={values.flightAirlines || []}
      onChange={nextValue => {
        onChange({
          ...values,
          flightAirlines: nextValue,
          prevChangedFilter: Options.flightAirlines,
        });
      }}
    />
  );
};

export default Airlines;
