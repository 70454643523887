import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { LocationFragmentDoc } from 'graphql/location/Location.generated';
export type DestinationFragment = {
  __typename?: 'TravelDestination';
  id: string;
  name: string;
  type: Types.TravelDestinationType;
  mythosCode?: string | null;
  location?: {
    __typename?: 'GeoLocation';
    airport?: {
      __typename?: 'Airport';
      iata?: string | null;
      name?: string | null;
    } | null;
    continent?: {
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    } | null;
    countryGroup?: {
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    } | null;
    country?: {
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    } | null;
    region?: {
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    } | null;
    destination?: {
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    } | null;
    resort?: {
      __typename?: 'GeoObject';
      id: string;
      name: string;
      type: Types.GeoType;
    } | null;
  } | null;
};

export const DestinationFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'destination' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'TravelDestination' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mythosCode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'location' },
                },
              ],
            },
          },
        ],
      },
    },
    ...LocationFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
