import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { IFilterCount } from 'components/domain/filters/Filters.types';
import { prepareRatingOptions } from 'components/domain/filters/search-filter-input/blocks/ratings/rating.utils';
import RatingFilter, {
  IRatingFilterProps,
} from 'components/domain/filters/search-filter-input/blocks/ratings/RatingFilter';
import { E_FILTER_RATING_TYPE } from './Rating.types';

type TProps = {
  tripAdvisorRatingValue: IRatingFilterProps['value'];
  tripAdvisorRatingOnChange: IRatingFilterProps['onChange'];
  tripAdvisorRatingOptions: IRatingFilterProps['options'];
  loading?: boolean;
  counts?: IFilterCount[];
};

const RatingFilterWrapper = styled(RatingFilter)({
  margin: 0,
});

const TripAdviserRating: React.FC<TProps> = ({
  loading,
  counts,
  tripAdvisorRatingValue,
  tripAdvisorRatingOnChange,
  tripAdvisorRatingOptions,
}: TProps) => {
  const [t] = useTranslation('filters');

  const taRatingOptions = prepareRatingOptions(
    tripAdvisorRatingValue,
    tripAdvisorRatingOptions,
    counts
  );

  return (
    <RatingFilterWrapper
      name={E_FILTER_RATING_TYPE.TRIP_ADVISOR}
      testId={E_FILTER_RATING_TYPE.TRIP_ADVISOR}
      value={tripAdvisorRatingValue}
      onChange={tripAdvisorRatingOnChange}
      activeIconName="tripadvisorone"
      inactiveIconName="tripadvisorempty"
      title={t('rating.tripadvisor.label')}
      options={taRatingOptions}
      loading={loading}
    />
  );
};

export default TripAdviserRating;
