import React from 'react';
import FilterCheckBoxGroup, {
  IFilterCheckBoxGroupProps,
} from 'components/domain/filters/FilterCheckBoxGroup';
import type { IFilterCount } from 'components/domain/filters/Filters.types';

interface IAdditionalProductFeatureFilterProps
  extends IFilterCheckBoxGroupProps {
  loading?: boolean;
  counts?: IFilterCount[];
}

const AdditionalProductFeatureFilter: React.FC<IAdditionalProductFeatureFilterProps> = ({
  value,
  options,
  onChange,
  groupId,
  loading,
  counts,
}) => {
  return (
    <FilterCheckBoxGroup
      loading={loading}
      value={value}
      options={options}
      groupId={groupId}
      onChange={onChange}
      counts={counts}
    />
  );
};

export default AdditionalProductFeatureFilter;
