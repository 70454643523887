import React from 'react';
import FilterCheckBoxGroup from 'components/domain/filters/FilterCheckBoxGroup';
import { FilterOptions as Options } from 'components/domain/filters/Filters.constants';
import type { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import { useLoadCount } from 'components/domain/filters/search-filter-input/blocks/useLoadCount';

const RoomType: React.FC<TComponentProps> = ({
  values,
  countLoaders,
  onChange,
  options,
  inView,
  loadWhenInView,
}: TComponentProps) => {
  const { loading, counts } = useLoadCount({
    useRequest: countLoaders.roomTypes,
    inView,
    loadWhenInView,
  });

  return (
    <FilterCheckBoxGroup
      loading={loading}
      groupId="roomTypes"
      onChange={nextValue => {
        onChange({
          ...values,
          roomTypes: nextValue.length ? nextValue : null,
          prevChangedFilter: Options.roomTypes,
        });
      }}
      options={options.roomTypes}
      value={values.roomTypes || []}
      counts={counts}
    />
  );
};

export default React.memo(RoomType);
