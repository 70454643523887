import { useTranslation } from 'next-i18next';
import React from 'react';
import { FilterOptions as Options } from 'components/domain/filters/Filters.constants';
import { IFilterCount } from 'components/domain/filters/Filters.types';
import {
  useGetAirports,
  useGetTransformedOptions,
} from 'components/domain/filters/search-filter-input/blocks/departure-arrival/departure-arrival.utils';
import { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import { useLoadCount } from 'components/domain/filters/search-filter-input/blocks/useLoadCount';
import TimeRangeInput from 'components/domain/filters/time-range-input/TimeRangeInput';

const DirectDeparture: React.FC<TComponentProps> = (props: TComponentProps) => {
  const {
    onChange,
    options,
    values,
    countLoaders,
    inView,
    loadWhenInView,
  } = props;
  const [t] = useTranslation('filters');

  const { loading, counts } = useLoadCount({
    useRequest: countLoaders.directFlightDepartureTime,
    inView,
    loadWhenInView,
  });

  const { directFlightDepartureAirport } = useGetAirports();

  const transformedOptions = useGetTransformedOptions(
    options.directFlightDepartureTime,
    counts as IFilterCount[] | undefined
  );

  if (!options.directFlightDepartureTime) return null;

  const flightChange = directDeparture =>
    onChange({
      ...values,
      directFlightDepartureTime: directDeparture,
      prevChangedFilter: Options.directFlightDepartureTime,
    });

  return (
    <TimeRangeInput
      title={`${t('flightDepartureTime.label')} ${
        directFlightDepartureAirport?.name || ''
      }`}
      name="directFlightDepartureTime"
      value={values.directFlightDepartureTime}
      options={transformedOptions}
      testId="directFlightDepartureTime"
      onChange={flightChange}
      className="flightTimesInput"
      loading={loading}
    />
  );
};

export default DirectDeparture;
