import { useTranslation } from 'next-i18next';
import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { Checkbox } from '@hotelplan/components.common.checkbox';
import { PageType } from '@hotelplan/graphql.types';
import { usePageType } from '@hotelplan/libs.context.page-type';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import FilterCheckBoxGroup from 'components/domain/filters/FilterCheckBoxGroup';

interface IFlightAirlinesFilterProps {
  value: string[];
  onChange: (nextValues: string[]) => void;
  loading?: boolean;
  options?: Array<{ id: string; count: number; caption: string }>;
}

const FlightAirlinesSkeletonWrapper = styled.div.attrs({
  'data-id': `flight-air-skeleton`,
})(
  sx2CssThemeFn({
    py: [3, '11px'],
    '.flight-airlines-checkbox': {
      py: 2,
      px: [3, '10px'],
    },
    '.content-loader-wrap': {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between',
    },
  })
);

const FlightAirlinesSkeleton: React.FC = () => {
  return (
    <FlightAirlinesSkeletonWrapper>
      {Array.from({ length: 3 }, (_, i) => (
        <Checkbox
          key={i}
          disabled
          className="flight-airlines-checkbox"
          content={
            <div className="content-loader-wrap">
              <ContentLoader
                width={150}
                height={18}
                speed={2}
                style={{ width: '150px', height: '18px' }}
              >
                <rect width={150} height={18} rx={8} />
              </ContentLoader>
              <ContentLoader
                width={30}
                height={18}
                speed={2}
                style={{ width: '30px', height: '18px' }}
              >
                <rect width={30} height={18} rx={8} />
              </ContentLoader>
            </div>
          }
        />
      ))}
    </FlightAirlinesSkeletonWrapper>
  );
};

const TextWrapper = styled.p.attrs({
  'data-id': `text-wrapper`,
})(({ theme: { space, media, fontSizes, colors } }) => ({
  color: colors.secondary,
  padding: space[3],
  fontSize: fontSizes[1],
  [media.tablet]: {
    fontSize: fontSizes[2],
  },
}));

function selectAirlines(options, values) {
  if (values?.length) {
    return values;
  }

  return options?.map(a => a.id) || [];
}

const FlightAirlinesFilter: React.FC<IFlightAirlinesFilterProps> = ({
  value,
  onChange,
  options,
  loading,
}) => {
  const [t] = useTranslation('filters');
  const showCount = usePageType<PageType>() !== PageType.Orl;

  const airlineOptions = options?.map(({ id, caption }) => ({ id, caption }));

  const noAirlines = !airlineOptions || airlineOptions.length === 0;

  if (loading) return <FlightAirlinesSkeleton />;

  if (noAirlines) return <TextWrapper>{t('airlines.noOptions')}</TextWrapper>;

  const airlineCounts = options?.map(({ id, count }) => ({ id, count }));

  return (
    <FilterCheckBoxGroup
      value={selectAirlines(airlineOptions, value)}
      loading={loading}
      counts={airlineCounts}
      options={airlineOptions}
      groupId={'airlinesGroup'}
      onChange={onChange}
      showCount={showCount}
    />
  );
};

export default FlightAirlinesFilter;
