import noop from 'lodash/noop';
import React, { createContext, useContext, useState } from 'react';

export interface IFilterCountErrorsContext {
  withErrors?: boolean;
  saveWithErrors: (flag: boolean) => void;
}

const FilterCountErrorsContext = createContext<IFilterCountErrorsContext>({
  withErrors: false,
  saveWithErrors: noop,
});

export const FilterCountErrorsProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [withErrors, setWithErrors] = useState<boolean>(false);
  const saveWithErrors = (flag: boolean): void => {
    setWithErrors(flag);
  };
  return (
    <FilterCountErrorsContext.Provider value={{ withErrors, saveWithErrors }}>
      {children}
    </FilterCountErrorsContext.Provider>
  );
};

export function useFilterCountErrorsContext() {
  return useContext(FilterCountErrorsContext);
}
