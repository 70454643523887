import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const MAP_ACCESS_PREVIEW_HEIGHT = 350;

const MapAccessLayout = styled.div.attrs({
  'data-id': `map-access-layout-id`,
})(
  sx2CssThemeFn({
    position: 'relative',
    width: '100%',
    height: ['auto', MAP_ACCESS_PREVIEW_HEIGHT],
  })
);

export default MapAccessLayout;
