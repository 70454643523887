import { useGoogleMap } from '@react-google-maps/api';
import React, { useEffect } from 'react';
import { mapCoordinatesToLatLng } from '@hotelplan/components.common.map-pin';
import { GeoCoordinates } from '@hotelplan/graphql.types';

interface IMapAccessPreviewItemsProps extends React.PropsWithChildren<{}> {
  items: { coordinates: GeoCoordinates }[];
}

const MAP_PREVIEW_BOUNDS_PADDING = 30;

const MapAccessPreviewItems: React.FC<IMapAccessPreviewItemsProps> = props => {
  const { items, children } = props;
  const map = useGoogleMap();

  useEffect(() => {
    if (!map || !items.length) return;

    if (items.length === 1) {
      map.setCenter(mapCoordinatesToLatLng(items[0].coordinates));
      map.setZoom(13);
    } else {
      const bounds = new google.maps.LatLngBounds();

      items.forEach(item => {
        bounds.extend(mapCoordinatesToLatLng(item.coordinates));
      });

      requestAnimationFrame(() => {
        map.fitBounds(bounds, MAP_PREVIEW_BOUNDS_PADDING);
      });
    }
  }, [map, items]);

  return <>{children}</>;
};

export default MapAccessPreviewItems;
