import React from 'react';
import styled from 'styled-components';

interface IMapControlsOverlayProps extends React.PropsWithChildren<{}> {
  onClick?(): void;
  className?: string;
}

const MapOverlay = styled.div({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  pointerEvents: 'none',
  zIndex: 2,
});

const MapControlsOverlay: React.FC<IMapControlsOverlayProps> = props => {
  const { children, onClick, className } = props;
  return (
    <MapOverlay className={className} onClick={onClick}>
      {children}
    </MapOverlay>
  );
};

export default MapControlsOverlay;
