import React from 'react';
import {
  PriceRangeInput,
  useGetSingleRangeInputTooltip,
} from '@hotelplan/components.common.double-range';
import { FilterOptions as Options } from 'components/domain/filters/Filters.constants';
import type { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import { useLoadCount } from 'components/domain/filters/search-filter-input/blocks/useLoadCount';

const MaxPricePerPerson: React.FC<TComponentProps> = ({
  values,
  countLoaders,
  onChange,
  inView,
  loadWhenInView,
}: TComponentProps) => {
  const { loading, minPrice } = useLoadCount({
    useRequest: countLoaders.maxPricePerPerson,
    inView,
    loadWhenInView,
  });

  const getTooltip = useGetSingleRangeInputTooltip();

  const value = values.maxPricePerPerson
    ? parseInt(values.maxPricePerPerson, 10)
    : null;

  return (
    <PriceRangeInput
      min={minPrice || 0}
      value={value}
      onChange={nextValue => {
        onChange({
          ...values,
          maxPricePerPerson: nextValue?.toString() || null,
          prevChangedFilter: Options.maxPricePerPerson,
        });
      }}
      loading={loading}
      disabled={typeof minPrice !== 'number'}
      getTooltip={getTooltip}
    />
  );
};

export default MaxPricePerPerson;
