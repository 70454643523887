import { useMemo } from 'react';
import { FlightType } from '@hotelplan/graphql.types';
import { useSearchState } from '@hotelplan/libs.search-state';
import type {
  IFilterCount,
  IFilterOption,
} from 'components/domain/filters/Filters.types';
import type { ICanonicalAirport } from 'components/domain/flightAirport/FlightAirport.types';
import type { IFlightSRLState } from 'components/domain/flightSRL';

export const useGetTransformedOptions = (
  options: IFilterOption[] | undefined,
  counts: IFilterCount[] | undefined
) => {
  return useMemo(
    () =>
      options
        ? options.map(option => {
            const count =
              counts?.find(countData => countData.id === option.id)?.count || 0;

            return {
              id: option.id,
              label: option.caption,
              count,
              disabled: !count,
            };
          })
        : [],
    [options, counts]
  );
};

type Airports = {
  directFlightDepartureAirport: ICanonicalAirport | null;
  directFlightArrivalAirport: ICanonicalAirport | null;
  returnFlightDepartureAirport: ICanonicalAirport | null;
  returnFlightArrivalAirport: ICanonicalAirport | null;
};

export const useGetAirports = (): Airports => {
  const {
    state: { searchControl },
  } = useSearchState<IFlightSRLState>();

  return useMemo(() => {
    const isReturn = searchControl?.flightType === FlightType.Return;
    const directFlightDepartureAirport =
      searchControl?.flightPartitions?.[0].departureAirport || null;
    const directFlightArrivalAirport =
      searchControl?.flightPartitions?.[0].arrivalAirport || null;
    const returnFlightDepartureAirport = isReturn
      ? directFlightArrivalAirport
      : searchControl?.flightPartitions?.[1].departureAirport || null;
    const returnFlightArrivalAirport = isReturn
      ? directFlightDepartureAirport
      : searchControl?.flightPartitions?.[1].arrivalAirport || null;

    return {
      directFlightDepartureAirport,
      directFlightArrivalAirport,
      returnFlightDepartureAirport,
      returnFlightArrivalAirport,
    };
  }, [searchControl]);
};
