import { useTranslation } from 'next-i18next';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@hotelplan/components.common.checkbox';
import { Icon } from '@hotelplan/components.common.icon';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { mapFeatureNameToIcon } from '@hotelplan/libs.mappers';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const getOptionColor = (count: number, active: boolean): string => {
  if (!count) return 'secondary';
  return active ? 'primary' : '#000000';
};

const getOptionBackgroundColor = (
  disabled: boolean,
  active: boolean
): string => {
  if (active) return 'white';
  if (disabled) return 'bgLightGrey';
  return 'white';
};

const HolidayTypeWrap = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    mb: 1,
    px: [3, '5px'],
    py: [3, '11px'],
  })
);

export interface IHolidayTypeInputProps {
  name: string;
  value: string[];
  onChange: (nextValue: string[]) => void;
  options: Array<{
    id: string;
    caption: string;
  }>;
  counts?: { count: number; id: string }[];
  loading: boolean;
}

const HolidayCheckbox = styled(Checkbox)<{
  selected: boolean;
  active: boolean;
  disabledOnly: boolean;
  disabled: boolean;
}>(
  sx2CssThemeFn({
    border: '1px solid',
    margin: '-1px -1px -1px 0',
    cursor: 'pointer',
    flex: ['1 0 25%', '1 0 25%', '1 0 25%'],
    height: ['80px', '87px'],
    p: '2px',
    lineHeight: '1',
    fontSize: 1,
    wordBreak: 'break-word',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    mb: [0, 0],
    color: 'initial',
  }),
  ({ selected, active, disabledOnly, disabled }) =>
    sx2CssThemeFn({
      cursor: disabled && 'initial',
      color: selected ? 'primary' : 'black',
      backgroundColor: getOptionBackgroundColor(disabledOnly, active),
      borderColor: selected ? 'primary' : 'borderColor',
      zIndex: selected ? 1 : 0,
      ':hover,:focus': {
        color: selected ? 'interactionPrimary' : 'black',
        textDecoration: disabled ? 'none' : 'underline',
        '.checkbox-icon-wrapper': {
          color: disabled || !active ? null : 'interactionPrimary',
        },
      },
    })
);

const CheckboxContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const CheckboxIconWrapper = styled.span<{
  count: number;
  active: boolean;
}>(
  ({ theme: { space } }) => ({
    textAlign: 'center',
    marginBottom: space[1],
  }),
  ({ count, active }) =>
    sx2CssThemeFn({
      color: getOptionColor(count, active),
    })
);

const CheckboxIcon = styled(Icon)(
  sx2CssThemeFn({
    variant: [null, 'icons.lg'],
  })
);

const CheckboxLabel = styled.p<{ disabled: boolean }>(
  ({ theme: { fontSizes } }) => ({
    hyphens: 'manual',
    fontSize: fontSizes[1],
    width: '100%',
    textAlign: 'center',
  }),
  ({ disabled, theme: { colors } }) => ({
    color: disabled ? colors.secondary : undefined,
  })
);

const CheckboxCount = styled.div<{ disabled: boolean }>(
  ({ theme: { space } }) => ({
    position: 'absolute',
    right: space[1],
    top: space[1],
  }),
  ({ disabled, theme: { colors } }) => ({
    color: disabled ? colors.secondary : undefined,
  })
);

const HolidayTypeInput: React.FC<IHolidayTypeInputProps> = ({
  name,
  value,
  options: rawOptions,
  counts,
  onChange,
  loading,
}) => {
  const {
    i18n: { language },
  } = useTranslation('common');
  const options = rawOptions.map(option => {
    const count =
      counts?.find(countData => countData.id === option.id)?.count || 0;

    return {
      id: option.id,
      label: option.caption,
      active: value.includes(option.id),
      disabled: !count,
      count,
    };
  });

  const onChangeHandler = useCallback(
    (optionValue: string) => {
      const index = value.findIndex(item => item === optionValue);
      if (index < 0) {
        onChange([...value, optionValue]);
      } else {
        const modifiedValue = [...value];
        modifiedValue.splice(index, 1);
        onChange(modifiedValue);
      }
    },
    [onChange, value]
  );

  return (
    <HolidayTypeWrap>
      {options.map((option, index) => {
        const id = `${name}_${option.id}`;

        // Due to `HPWEBN-641` checked options should not be disabled
        // until user de-select them by himself
        const disabled = !option.active && option.disabled;

        return (
          <HolidayCheckbox
            key={id}
            name={option.id}
            value={option.id}
            onChange={onChangeHandler}
            disabled={disabled}
            testId={id}
            data-id={`holidayType[${index}]`}
            renderCustomControl={() => null}
            selected={(value || []).indexOf(option.id) > -1}
            active={option.active}
            disabledOnly={option.disabled}
            content={
              <CheckboxContent>
                <CheckboxIconWrapper
                  className="checkbox-icon-wrapper"
                  count={option.count}
                  active={option.active}
                >
                  <CheckboxIcon name={mapFeatureNameToIcon(option.id)} />
                </CheckboxIconWrapper>

                <CheckboxCount disabled={disabled}>
                  {loading ? (
                    <PlaceholderLoader
                      width={20}
                      height={14}
                      style={{ width: '20px', height: '14px' }}
                      uid="HolidayTypeInputCheckboxCount"
                    >
                      <rect width={20} height={14} rx={5} />
                    </PlaceholderLoader>
                  ) : (
                    option.count
                  )}
                </CheckboxCount>

                <CheckboxLabel
                  lang={language}
                  disabled={disabled}
                  dangerouslySetInnerHTML={{ __html: option.label }}
                />
              </CheckboxContent>
            }
          />
        );
      })}
    </HolidayTypeWrap>
  );
};

export default HolidayTypeInput;
