import React from 'react';
import { FilterOptions as Options } from 'components/domain/filters/Filters.constants';
import AdditionalProductFeatureFilter from 'components/domain/filters/search-filter-input/blocks/additional-product-feature/AdditionalProductFeatureFilter';
import type { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import { useLoadCount } from 'components/domain/filters/search-filter-input/blocks/useLoadCount';

const Beach: React.FC<TComponentProps> = ({
  values,
  countLoaders,
  onChange,
  options,
  inView,
  loadWhenInView,
}: TComponentProps) => {
  const { loading, counts } = useLoadCount({
    useRequest: countLoaders.beachFeatures,
    inView,
    loadWhenInView,
  });

  return (
    <AdditionalProductFeatureFilter
      loading={loading}
      counts={counts}
      onChange={nextValue => {
        onChange({
          ...values,
          beachFeatures: nextValue,
          prevChangedFilter: Options.beachFeatures,
        });
      }}
      groupId={Options.beachFeatures}
      options={options.beachFeatures}
      value={values.beachFeatures || []}
    />
  );
};

export default Beach;
