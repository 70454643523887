import React from 'react';
import { FilterOptions as Options } from 'components/domain/filters/Filters.constants';
import HolidayTypeInput from 'components/domain/filters/holiday-type-input/HolidayTypeInput';
import type { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import { useLoadCount } from 'components/domain/filters/search-filter-input/blocks/useLoadCount';
import { filterElementPlacementById } from 'components/domain/srl/SRL.mappers';

const Main: React.FC<TComponentProps> = ({
  values,
  countLoaders,
  onChange,
  options,
  inView,
  loadWhenInView,
}: TComponentProps) => {
  const { loading, counts } = useLoadCount({
    useRequest: countLoaders.mainFeatures,
    inView,
    loadWhenInView,
  });

  const items =
    filterElementPlacementById(options.mainFeatures, `sustainability`, 2) || [];

  return (
    <HolidayTypeInput
      value={values.mainFeatures || []}
      name={Options.mainFeatures}
      counts={counts?.map(x => ({
        count: x.count,
        id: x.id,
      }))}
      onChange={nextValue => {
        onChange({
          ...values,
          mainFeatures: nextValue,
          prevChangedFilter: Options.mainFeatures,
        });
      }}
      options={items}
      loading={loading}
    />
  );
};

export default Main;
