import React from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { MainFilterComponentValuesFragment } from 'graphql/searchFilter/MainFilterComponentValues.generated';
import { mapMainFilterComponentValuesToFiltersFormState } from './Filters.mappers';
import type { IFiltersFormState } from './Filters.types';

export const filtersComponentPropsAreEqual = (
  prev: { loading?: boolean },
  next: { loading?: boolean }
): boolean => !!prev.loading && !!next.loading;

export const useFilterFormActions = <TState>(
  defaultFilters: MainFilterComponentValuesFragment,
  defaultFiltersLoading: boolean,
  setState: (
    nextState: React.SetStateAction<TState>,
    cb?: (nextState: TState) => void
  ) => void,
  postSubmit?: (state: TState) => void
) => {
  const { mobile } = useDeviceType();

  const onSubmit = (formState: IFiltersFormState): void => {
    setState(
      prev => ({
        ...prev,
        filters: mobile ? { ...formState, prevChangedFilter: null } : formState,
      }),
      postSubmit
    );
  };

  const onReset =
    !defaultFiltersLoading && defaultFilters
      ? (setValues: (defaultValues: IFiltersFormState) => void): void => {
          setValues(
            mapMainFilterComponentValuesToFiltersFormState(defaultFilters)
          );
        }
      : undefined;

  return {
    onSubmit,
    onReset,
  };
};
