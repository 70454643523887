import type { IFilterCount } from 'components/domain/filters/Filters.types';
import {
  IRatingFilterProps,
  IRatingFilterOption,
} from 'components/domain/filters/search-filter-input/blocks/ratings/RatingFilter';

export const prepareRatingOptions = (
  value: string | null | undefined,
  options: IRatingFilterProps['options'],
  counts: IFilterCount[] | undefined
): IRatingFilterOption[] => {
  return options.map(option => {
    const count = counts?.find(({ id }) => id === option.value)?.count || 0;

    return {
      ...option,
      count,
      disabled: option.value !== value && count === 0,
    };
  });
};
