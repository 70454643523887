export interface ISVGPathRectProps {
  x: number;
  y: number;
  width: number;
  height: number;
  r?: number;
}

export function getSVGRoundedRectShapeString({
  x,
  y,
  width,
  height,
  r = 0,
}: ISVGPathRectProps): string {
  return (
    `M${x + r},${y}` +
    `h${width - 2 * r}` +
    `a${r},${r} 0 0 1 ${r},${r}` +
    `v${height - 2 * r}` +
    `a${r},${r} 0 0 1 -${r},${r}` +
    `h-${width - 2 * r}` +
    `a${r},${r} 0 0 1 -${r},-${r}` +
    `v-${height - 2 * r}` +
    `a${r},${r} 0 0 1 ${r},-${r}` +
    `z`
  );
}
