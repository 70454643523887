import styled from 'styled-components';

export const FiltersRadioControl = styled.span<{
  checked: boolean;
  disabled: boolean;
}>(({ checked, disabled, theme: { colors, space } }) => ({
  display: 'inline-block',
  height: '16px',
  width: '16px',
  position: 'relative',
  border: checked ? '5px solid' : '1px solid',
  borderColor: checked ? colors.primary : colors.darkGray,
  borderRadius: '50%',
  order: '-1',
  marginRight: space[2],
  backgroundColor: colors.background,
  flexShrink: 0,
  ...(disabled
    ? {
        border: '1px solid',
        backgroundColor: `${colors.lightGreySixth}!important`,
        borderColor: colors.lightGreyFirst,
      }
    : {}),
}));
