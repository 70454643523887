import { useTranslation } from 'next-i18next';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useCurrentSearchContext } from 'components/domain/currentSearch/CurrentSearchContext';

const NotificationCardWrapper = styled.div.attrs({
  'data-id': 'noOffers',
})(
  ({ theme }) => theme.modals.notificationCardWrapWithError || {},
  ({ theme: { space } }) => ({
    paddingTop: space[3],
    paddingBottom: space[4],
    '.notification-card-button': {
      width: '100%',
    },
  })
);

const NotificationCardContent = styled.div.attrs({
  className: 'notification-content',
})(({ theme: { space } }) => ({
  '.notification-card-title': {
    fontSiz: '20px',
    marginBottom: space[2],
  },
  '.notification-card-text': {
    marginBottom: space[4],
  },
}));

const ExpiredFilter: React.FC<{ onCloseModal?: () => void }> = ({
  onCloseModal,
}) => {
  const { mobile } = useDeviceType();
  const { t } = useTranslation('filters');
  const {
    ref,
    showSearchControl,
    submitSearchControl,
  } = useCurrentSearchContext();

  const onRenewSearch = useCallback(() => {
    onCloseModal && onCloseModal();
    if (!mobile) {
      submitSearchControl && submitSearchControl();
    } else {
      showSearchControl && showSearchControl();
    }
    setTimeout(() => {
      if (ref && ref.current) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 0);
  }, [mobile, onCloseModal, ref, submitSearchControl, showSearchControl]);

  return (
    <NotificationCardWrapper>
      <NotificationCardContent>
        <h3 className="notification-card-title">{t('expiredFilter.title')}</h3>
        <p className="notification-card-text">{t('expiredFilter.text')}</p>
      </NotificationCardContent>
      <BsButton
        className="notification-card-button"
        type="button"
        onClick={onRenewSearch}
      >
        {t('expiredFilter.button')}
      </BsButton>
    </NotificationCardWrapper>
  );
};

export default ExpiredFilter;
