import React from 'react';
import { FilterOptions as Options } from 'components/domain/filters/Filters.constants';
import type { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import FlightStopoverDurationFilter from 'components/domain/filters/search-filter-input/blocks/stop-duration/FlightStopoverDurationFilter';
import { useLoadCount } from 'components/domain/filters/search-filter-input/blocks/useLoadCount';

const StopDuration: React.FC<TComponentProps> = ({
  values,
  countLoaders,
  onChange,
  inView,
  loadWhenInView,
}: TComponentProps) => {
  const { loading, maxDuration, minDuration } = useLoadCount({
    useRequest: countLoaders.flightStopOverDuration,
    inView,
    loadWhenInView,
  });

  return (
    <FlightStopoverDurationFilter
      loading={loading}
      maxDuration={maxDuration}
      minDuration={minDuration}
      minDurationSelected={values.flightStopOverDuration?.minSelected || null}
      maxDurationSelected={values.flightStopOverDuration?.maxSelected || null}
      onChange={(minSelected, maxSelected) => {
        onChange({
          ...values,
          flightStopOverDuration: {
            minSelected: minSelected.toString(),
            maxSelected: maxSelected.toString(),
          },
          prevChangedFilter: Options.flightStopOverDuration,
        });
      }}
    />
  );
};

export default StopDuration;
