import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
export type CheckboxGroupFilterValueFragment = {
  __typename?: 'CheckboxFilterComponent';
  selected: Array<string>;
};

export const CheckboxGroupFilterValueFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CheckboxGroupFilterValue' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CheckboxFilterComponent' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'selected' } },
        ],
      },
    },
  ],
} as unknown) as DocumentNode;
