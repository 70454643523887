import React from 'react';
import {
  SingleRangeInput,
  RangeInputHeader,
  IOption,
} from '@hotelplan/components.common.double-range';
import { flightDurationOptions } from '@hotelplan/constants';

export interface ITimeDurationRangeInputProps {
  label?: React.ReactNode;
  testId?: string;
  value: number | null;
  disabled?: boolean;
  loading?: boolean;
  onChange: (nextValue: number | null) => void;
  getTooltip?: (
    options: IOption<number | null>[],
    values: Array<number | null>
  ) => string;
}

const TimeDurationRangeInput: React.FC<ITimeDurationRangeInputProps> = props => {
  const {
    label,
    value,
    disabled,
    loading,
    onChange,
    testId = 'timeDurationInput',
    getTooltip,
  } = props;

  const tooltipTemplate = (amount: number | null) => {
    return (
      flightDurationOptions.find(option => option.value === amount)?.caption ||
      ''
    );
  };

  function onChangeHandler(next: number | null) {
    onChange(next === 0 ? null : next);
  }

  return (
    <RangeInputHeader>
      {label && (
        <p className="price-range-input-label" data-id="text">
          {label}
        </p>
      )}
      <SingleRangeInput<number | null>
        options={flightDurationOptions}
        value={value}
        onChange={onChangeHandler}
        tooltipTemplate={tooltipTemplate}
        testId={testId}
        loading={loading}
        disabled={disabled}
        getSingleRangeInputTooltip={getTooltip}
      />
    </RangeInputHeader>
  );
};

export default TimeDurationRangeInput;
