import { useMemo } from 'react';

export const useTimeOptions = (counts, options) => {
  return useMemo(
    () =>
      (options || []).map(({ id, caption: label }) => {
        const count = counts?.find(cd => cd.id === id)?.count || 0;
        return { id, label, count, disabled: !count };
      }),
    [counts, options]
  );
};
