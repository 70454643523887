import React from 'react';
import styled from 'styled-components';
import { withActiveStyles } from '@hotelplan/components.common.mixins';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { sx2CssTheme } from '@hotelplan/util.theme.sxc';

interface IFilterOptionContentProps {
  loading?: boolean;
  text?: React.ReactNode;
  count?: number | null | undefined;
  rating?: React.ReactNode;
  disabled?: boolean;
  showCount?: boolean;
  uid: string;
}

const FilterOptionContentWrapper = styled.div<{ disabled: boolean }>(
  ({ theme: { space, media, colors, FONT_SIZE } }) => ({
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    '.filter-option-text': {
      marginRight: space[1],
      [media.tablet]: {
        marginRight: space[2],
      },
    },
    '.filter-option-rating': {
      alignItems: 'center',
      color: colors.primary,
    },
    '.content-loader-wrap': {
      ...FONT_SIZE.SMALL,
      marginLeft: 'auto',
      color: colors.darkGray,
      cursor: 'initial',
    },
  }),
  ({ disabled, theme: { colors } }) => ({
    cursor: disabled ? 'initial' : 'pointer',
    '.filter-option-text': {
      color: disabled ? colors.secondary : 'inherit',
    },
    '.filter-option-rating': {
      opacity: disabled ? '0.6' : '1',
    },
    '.content-loader-wrap': {
      color: disabled ? colors.secondary : colors.darkGray,
    },
  })
);

export const FilterOptionContent: React.FC<IFilterOptionContentProps> = ({
  loading = false,
  text,
  count,
  rating = null,
  disabled = false,
  showCount = true,
  uid,
}) => {
  return (
    <FilterOptionContentWrapper
      className="filter-option-content"
      disabled={disabled}
    >
      {text && (
        <span data-id="text" className="filter-option-text">
          {text}
        </span>
      )}
      <div className="filter-option-rating">{rating}</div>

      {showCount && (
        <span className="content-loader-wrap">
          {loading ? (
            <PlaceholderLoader
              uid={`${uid}_option-loading`}
              width={30}
              height={18}
              speed={2}
              style={{ width: '30px', height: '18px' }}
              data-id="option-loading"
            >
              <rect width={30} height={18} rx={8} />
            </PlaceholderLoader>
          ) : (
            count
          )}
        </span>
      )}
    </FilterOptionContentWrapper>
  );
};

interface IFilterOptionWrapperProps extends React.PropsWithChildren<{}> {
  active?: boolean;
}

const FilterOptionWrap = styled.div<{ active?: boolean }>(
  ({ active, theme, theme: { colors } }) =>
    withActiveStyles(
      active,
      sx2CssTheme(theme, {
        py: 2,
        px: 3,
        backgroundColor: 'inherit',
        '.label': {
          lineHeight: [null, 'inherit'],
          '.customCheckboxInput,.filter-option-text': {
            ml: 0,
          },
          mb: [0, 0],
          color: 'initial',
          pl: 0,
          '&:hover input ~ .customCheckboxInput': {
            backgroundColor: colors.background,
          },
        },
        ':hover': {
          '.filter-option-content:not([disabled])': {
            '.filter-option-text': {
              textDecoration: 'underline',
            },
          },
        },
      }),
      {
        ':hover,:focus': {
          '.radio-control': {
            borderColor: colors.interactionPrimary,
          },
        },
        backgroundColor: colors.lightGreySixth,
      }
    )
);

export const FilterOptionWrapper: React.FC<IFilterOptionWrapperProps> = ({
  active = false,
  children,
}) => {
  return <FilterOptionWrap active={active}>{children}</FilterOptionWrap>;
};
