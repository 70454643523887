import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { IFilterCount } from 'components/domain/filters/Filters.types';
import { prepareRatingOptions } from 'components/domain/filters/search-filter-input/blocks/ratings/rating.utils';
import RatingFilter, {
  IRatingFilterProps,
} from 'components/domain/filters/search-filter-input/blocks/ratings/RatingFilter';
import { E_FILTER_RATING_TYPE } from './Rating.types';

type TProps = {
  hotelPlanRatingValue: IRatingFilterProps['value'];
  hotelPlanRatingOnChange: IRatingFilterProps['onChange'];
  hotelPlanRatingOptions: IRatingFilterProps['options'];
  loading?: boolean;
  counts?: IFilterCount[];
};

const RatingFilterWrapper = styled(RatingFilter)(({ theme: { space } }) => ({
  margin: 0,
  marginBottom: space[4],
}));

const HotelplanRating: React.FC<TProps> = ({
  loading,
  counts,
  hotelPlanRatingValue,
  hotelPlanRatingOnChange,
  hotelPlanRatingOptions,
}: TProps) => {
  const [t] = useTranslation('filters');

  const hpRatingOptions = prepareRatingOptions(
    hotelPlanRatingValue,
    hotelPlanRatingOptions,
    counts
  );

  return (
    <RatingFilterWrapper
      name={E_FILTER_RATING_TYPE.HOTELPLAN}
      testId={E_FILTER_RATING_TYPE.HOTELPLAN}
      value={hotelPlanRatingValue}
      onChange={hotelPlanRatingOnChange}
      title={t('rating.hotelplan.label')}
      activeIconName="hotelplanbewertung"
      options={hpRatingOptions}
      loading={loading}
    />
  );
};

export default HotelplanRating;
