import React from 'react';
import { FilterOptions as Options } from 'components/domain/filters/Filters.constants';
import AdditionalProductFeatureFilter from 'components/domain/filters/search-filter-input/blocks/additional-product-feature/AdditionalProductFeatureFilter';
import type { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import { useLoadCount } from 'components/domain/filters/search-filter-input/blocks/useLoadCount';

const Hotel: React.FC<TComponentProps> = ({
  values,
  countLoaders,
  onChange,
  options,
  inView,
  loadWhenInView,
}: TComponentProps) => {
  const { loading, counts } = useLoadCount({
    useRequest: countLoaders.hotelFeatures,
    inView,
    loadWhenInView,
  });

  return (
    <AdditionalProductFeatureFilter
      loading={loading}
      counts={counts}
      onChange={nextValue => {
        onChange({
          ...values,
          hotelFeatures: nextValue,
          prevChangedFilter: Options.hotelFeatures,
        });
      }}
      groupId={Options.hotelFeatures}
      options={options.hotelFeatures}
      value={values.hotelFeatures || []}
    />
  );
};

export default Hotel;
