import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import type { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import DepartureTime from 'components/domain/filters/search-filter-input/blocks/flight-time/DepartureTime';
import ReturnTime from 'components/domain/filters/search-filter-input/blocks/flight-time/ReturnTime';

const FlightTimesFilterWrapper = styled.div(
  sx2CssThemeFn({
    py: 3,
    '.flight-departure-time': {
      mb: 3,
    },
  })
);

const FlightOptions: React.FC<TComponentProps> = (props: TComponentProps) => {
  return (
    <FlightTimesFilterWrapper>
      <DepartureTime {...props} />
      <ReturnTime {...props} />
    </FlightTimesFilterWrapper>
  );
};

export default FlightOptions;
