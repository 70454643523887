import React from 'react';
import styled from 'styled-components';
import { FlightType } from '@hotelplan/graphql.types';
import { useSearchState } from '@hotelplan/libs.search-state';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import DirectArrival from 'components/domain/filters/search-filter-input/blocks/departure-arrival/DirectArrival';
import DirectDeparture from 'components/domain/filters/search-filter-input/blocks/departure-arrival/DirectDeparture';
import ReturnArrival from 'components/domain/filters/search-filter-input/blocks/departure-arrival/ReturnArrival';
import ReturnDeparture from 'components/domain/filters/search-filter-input/blocks/departure-arrival/ReturnDeparture';
import { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import { IFlightSRLState } from 'components/domain/flightSRL/FlightSRL.types';

const FlightTimesFilterWrap = styled.div(
  sx2CssThemeFn({
    '.fieldset-title': {
      marginLeft: 3,
      color: 'darkGray',
    },
    py: 3,
    '.flightTimesInput:not(:last-child)': { mb: 3 },
  })
);

const DepartureArrivalTimes: React.FC<TComponentProps> = (
  props: TComponentProps
) => {
  const {
    state: { searchControl },
  } = useSearchState<IFlightSRLState>();

  const isOneWay = searchControl?.flightType === FlightType.OneWay;

  return (
    <FlightTimesFilterWrap>
      <DirectDeparture {...props} />
      <DirectArrival {...props} />
      {!isOneWay && <ReturnDeparture {...props} />}
      {!isOneWay && <ReturnArrival {...props} />}
    </FlightTimesFilterWrap>
  );
};

export default DepartureArrivalTimes;
