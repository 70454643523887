import noop from 'lodash/noop';
import React, {
  createContext,
  FC,
  useContext,
  useState,
  useMemo,
  MutableRefObject,
} from 'react';
import { useSelfUpdatingRef } from '@hotelplan/libs.hooks-react';
import { IDestination } from './DestinationStack.mappers';

type IDestinations = IDestination[];

interface IMapPositionArtifacts {
  zoom: number;
  coordinates: {
    latitude: number;
    longitude: number;
  };
}

export interface IDestinationStackContext {
  stack: IDestinations[];
  position: IMapPositionArtifacts | undefined;
  current: IDestinations | null;
  previous: IDestinations | null;
  go(next: IDestinations, mapPosition?: IMapPositionArtifacts): void;
  back(): IMapPositionArtifacts | undefined;
  reset(): void;
}

const DestinationStackContext = createContext<IDestinationStackContext>({
  stack: [],
  position: undefined,
  current: null,
  previous: null,
  go: noop,
  back: () => undefined,
  reset: noop,
});

interface IDestinationStackProviderProps extends React.PropsWithChildren<{}> {
  contextRef?: React.RefObject<IDestinationStackContext>;
  destinations?: IDestinations | null;
}

export const DestinationStackProvider: FC<IDestinationStackProviderProps> = props => {
  const { children, contextRef, destinations = null } = props;

  const initialStack = destinations ? [destinations] : [];
  const initialStackRef = useSelfUpdatingRef(initialStack);

  const [stack, setStack] = useState<IDestinations[]>(initialStack);
  const [positionStack, setPositionStack] = useState<
    (IMapPositionArtifacts | undefined)[]
  >(Array.from({ length: initialStack.length }));

  function go(next: IDestinations, position?: IMapPositionArtifacts) {
    setStack(prev => [...prev, next]);
    setPositionStack(prev => [...prev, position]);
  }

  function back() {
    const lastPosition = positionStack[positionStack.length - 1];
    setStack(prev => prev.slice(0, -1));
    setPositionStack(prev => prev.slice(0, -1));
    return lastPosition;
  }

  function reset() {
    setStack(initialStackRef.current);
  }

  const context: IDestinationStackContext = useMemo(
    () => ({
      position: positionStack.length
        ? positionStack[positionStack.length - 1]
        : undefined,
      current: stack.length ? stack[stack.length - 1] : null,
      previous: stack.length > 1 ? stack[stack.length - 2] : null,
      stack,
      go,
      back,
      reset,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stack, positionStack]
  );

  if (contextRef) {
    (contextRef as MutableRefObject<IDestinationStackContext>).current = context;
  }

  return (
    <DestinationStackContext.Provider value={context}>
      {children}
    </DestinationStackContext.Provider>
  );
};

export function useDestinationStack() {
  return useContext(DestinationStackContext);
}
