import hoistNonReactStatics from 'hoist-non-react-statics';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { getComponentDisplayName } from '@hotelplan/libs.utils';

function withViewportLoad<TProps extends object = {}>(
  Component: React.FC<TProps>
): React.FC<TProps & { inView: boolean }> {
  function WithViewportLoadComponent(props: React.PropsWithChildren<TProps>) {
    const { ref, inView } = useInView({
      threshold: 0,
      delay: 100,
      trackVisibility: true,
    });

    return (
      <div ref={ref}>
        <Component {...props} inView={inView} loadWhenInView={true}>
          {props.children}
        </Component>
      </div>
    );
  }

  hoistNonReactStatics(WithViewportLoadComponent, Component);

  if (process.env.NODE_ENV === 'development') {
    WithViewportLoadComponent.displayName = `withViewportLoad(${getComponentDisplayName(
      Component
    )})`;
  }

  return WithViewportLoadComponent;
}

export default withViewportLoad;
