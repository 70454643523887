import isEqual from 'fast-deep-equal';
import { MutableRefObject, useEffect, useRef } from 'react';
import { useFormContext } from '@hotelplan/components.common.forms';
import type { ISearchStateContext } from '@hotelplan/libs.search-state';

export default function useFiltersSubmitController<
  TState extends {},
  TGlobalState extends { filters: TState | null }
>(
  stateContext: ISearchStateContext<TGlobalState>,
  postSubmit?: (state: TGlobalState) => void,
  onSetStateHook: (state: TGlobalState) => TGlobalState = state => state
) {
  const {
    state: { filters: globalFilters },
    setState,
  } = stateContext;
  const { values: filterValues } = useFormContext<TState>();

  const prevValuesRef = useRef<TState>(null) as MutableRefObject<TState>;

  useEffect(() => {
    const prevValues = prevValuesRef.current;
    prevValuesRef.current = filterValues;

    // Ignore other changes but filterValues
    if (filterValues === prevValues) return;

    if (isEqual(globalFilters, filterValues)) return;
    setState(
      prev =>
        onSetStateHook({
          ...prev,
          filters: filterValues,
        }),
      postSubmit
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, globalFilters, setState]);
}
