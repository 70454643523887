import { MutableRefObject, useRef, useState } from 'react';
import type { ISelectableItem } from '@hotelplan/components.common.map-pin';
import useMapAutoSize from 'components/domain/map/useMapAutoSize';
import { SrlMapProductPinFragment } from 'graphql/srlMap/SRLMapFragments.generated';

type ProductPin = SrlMapProductPinFragment;

export default function useMapForm() {
  const { mapContainerRef, mapProviderProps } = useMapAutoSize();

  const selectedMapObjectRef = useRef<ISelectableItem | null>(
    null
  ) as MutableRefObject<ISelectableItem | null>;

  const [selectedObjects, setSelectedObjects] = useState<ProductPin[]>([]);

  const onSelectObjects = (
    objects: ProductPin[],
    selectableMapItem: ISelectableItem
  ): void => {
    selectedMapObjectRef.current?.deselect();
    selectedMapObjectRef.current = selectableMapItem;
    selectableMapItem.select();
    setSelectedObjects(objects);
  };

  const deselect = (): void => {
    selectedMapObjectRef.current?.deselect();
    selectedMapObjectRef.current = null;
    setSelectedObjects([]);
  };

  return {
    mapContainerRef,
    mapProviderProps,
    selectedObjects,
    onSelectObjects,
    deselect,
  };
}
