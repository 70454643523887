import { useTranslation } from 'next-i18next';
import React from 'react';
import { FilterOptions as Options } from 'components/domain/filters/Filters.constants';
import { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import { useTimeOptions } from 'components/domain/filters/search-filter-input/blocks/flight-time/flight-time';
import { useLoadCount } from 'components/domain/filters/search-filter-input/blocks/useLoadCount';
import TimeRangeInput from 'components/domain/filters/time-range-input/TimeRangeInput';

const ReturnTime: React.FC<TComponentProps> = (props: TComponentProps) => {
  const {
    onChange,
    options,
    values,
    countLoaders,
    inView,
    loadWhenInView,
  } = props;
  const [t] = useTranslation('filters');

  const { loading, counts } = useLoadCount({
    useRequest: countLoaders.returnFlightDepartureTime,
    inView,
    loadWhenInView,
  });

  const returnTimeOptions = useTimeOptions(
    counts,
    options.returnFlightDepartureTime
  );

  if (!options.returnFlightDepartureTime) return null;

  const flightChange = returnDeparture =>
    onChange({
      ...values,
      returnFlightDepartureTime: returnDeparture,
      prevChangedFilter: Options.returnFlightDepartureTime,
    });

  return (
    <TimeRangeInput
      title={t('returnTime')}
      name="returnTime"
      value={values.returnFlightDepartureTime}
      options={returnTimeOptions}
      testId="returnTime"
      onChange={flightChange}
      loading={loading}
    />
  );
};

export default ReturnTime;
