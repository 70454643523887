import React, { useRef } from 'react';
import styled from 'styled-components';
import { MapProvider } from '@hotelplan/components.common.map';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import MapControlsOverlay from 'components/domain/map/MapControlsOverlay';

interface IMapAccessPreview extends React.PropsWithChildren<{}> {
  onOpen?(): void;
}

const MapProviderWrapper = styled.div({
  width: '100%',
  height: '100%',
  '.map-controls-overlay': {
    pointerEvents: 'auto',
    cursor: 'pointer',
  },
});

const MapAccessPreview: React.FC<IMapAccessPreview> = props => {
  const { children, onOpen } = props;
  const mapWrapperRef = useRef<HTMLDivElement>(null);
  const mapSize = useElementSize(mapWrapperRef);

  return (
    <MapProviderWrapper ref={mapWrapperRef}>
      <MapProvider
        styles={{
          position: 'relative',
          width: `${mapSize.w}px`,
          height: `${mapSize.h}px`,
        }}
        options={{
          disableDefaultUI: true,
        }}
      >
        {children}
        <MapControlsOverlay className="map-controls-overlay" onClick={onOpen} />
      </MapProvider>
    </MapProviderWrapper>
  );
};

export default MapAccessPreview;
