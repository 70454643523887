import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { RadioButton } from '@hotelplan/components.common.buttons';
import { Icon } from '@hotelplan/components.common.icon';
import { RadioGroupInput } from '@hotelplan/components.common.radio-group';
import { Rating } from '@hotelplan/components.common.rating';
import {
  FilterOptionWrapper,
  FilterOptionContent,
} from 'components/domain/filters/filter-option';
import { FiltersRadioControl } from 'components/domain/filters/Filters.styles';
import { filtersComponentPropsAreEqual } from 'components/domain/filters/Filters.utils';
import { E_FILTER_RATING_TYPE } from './Rating.types';

export interface IRatingFilterOption {
  value: string;
  label: React.ReactNode;
  disabled?: boolean;
  count?: number;
}

export interface IRatingFilterProps {
  testId?: string;
  name: E_FILTER_RATING_TYPE;
  value?: string | null;
  options: IRatingFilterOption[];
  title?: string;
  onChange: (nextValue: string | null) => void;
  activeIconName: string;
  inactiveIconName?: string;
  loading?: boolean;
  className?: string;
}

const ratingsIdsToNumberDict: { [key: string]: number } = {
  TA_ANY_RATING: 0,
  TA_MIN_5_STARS: 5,
  TA_MIN_4_STARS: 4,
  TA_MIN_3_STARS: 3,
  HP_ANY_RATING: 0,
  HP_MIN_6_STARS: 6,
  HP_MIN_5_STARS: 5,
  HP_MIN_4_STARS: 4,
  HP_MIN_3_STARS: 3,
};

const RatingRadioGroup = styled(RadioGroupInput)(
  ({ theme: { space, colors } }) => ({
    '.radio-group-container': {
      flexDirection: 'column',
    },
    '.fieldset-title': {
      marginLeft: space[3],
      color: colors.darkGray,
    },
  })
);

const FilterRatingItem = styled(Rating)<{ isHotelplanRatingType: boolean }>(
  ({ isHotelplanRatingType, theme: { media } }) => ({
    '.icon': {
      width: isHotelplanRatingType ? '24px' : '16px',
      height: isHotelplanRatingType ? '24px' : '16px',
      [media.mobile]: {
        width: isHotelplanRatingType ? '20px' : '16px',
        height: isHotelplanRatingType ? '20px' : '16px',
      },
    },
  })
);

const IconWrapper = styled(Icon)(({ theme: { space } }) => ({
  marginRight: space[1],
}));

const RatingFilter: React.FC<IRatingFilterProps> = props => {
  const {
    name,
    testId,
    value = null,
    title,
    onChange,
    options,
    activeIconName,
    inactiveIconName,
    className,
    loading,
  } = props;

  const { t } = useTranslation('filters');

  const maxRating: number = Math.max(
    ...options.map(option => ratingsIdsToNumberDict[option.value])
  );

  const ratingOptions = options.map(option => {
    const currentValue = ratingsIdsToNumberDict[option.value];
    const isAnyRating = currentValue === 0;

    return {
      value: option.value,
      disabled: option.disabled,
      content: (
        <FilterOptionContent
          uid="rating"
          text={isAnyRating ? option.label : t('least.label')}
          count={option.count}
          loading={loading}
          disabled={option.disabled}
          rating={
            isAnyRating ? null : (
              <FilterRatingItem
                isHotelplanRatingType={name === E_FILTER_RATING_TYPE.HOTELPLAN}
                maxRating={maxRating}
                rating={ratingsIdsToNumberDict[option.value]}
                ratingContent={<IconWrapper name={activeIconName} />}
                remainRatingContent={
                  inactiveIconName ? (
                    <IconWrapper name={inactiveIconName} />
                  ) : null
                }
              />
            )
          }
        />
      ),
    };
  });

  return (
    <RatingRadioGroup
      title={title}
      name={name}
      value={value || undefined}
      testId={testId}
      onChange={onChange}
      options={ratingOptions}
      className={className}
      renderCustomOption={option => {
        return (
          <FilterOptionWrapper active={option.checked}>
            <RadioButton
              {...option}
              renderCustomControl={() => {
                return (
                  <FiltersRadioControl
                    className="customCheckboxInput radio-control"
                    checked={option.checked}
                    disabled={option.disabled}
                  />
                );
              }}
            />
          </FilterOptionWrapper>
        );
      }}
    />
  );
};

export default React.memo(RatingFilter, filtersComponentPropsAreEqual);
