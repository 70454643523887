import { useTranslation } from 'next-i18next';
import React from 'react';
import { FilterOptions as Options } from 'components/domain/filters/Filters.constants';
import { IFilterCount } from 'components/domain/filters/Filters.types';
import {
  useGetAirports,
  useGetTransformedOptions,
} from 'components/domain/filters/search-filter-input/blocks/departure-arrival/departure-arrival.utils';
import { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import { useLoadCount } from 'components/domain/filters/search-filter-input/blocks/useLoadCount';
import TimeRangeInput from 'components/domain/filters/time-range-input/TimeRangeInput';

const ReturnArrival: React.FC<TComponentProps> = (props: TComponentProps) => {
  const {
    onChange,
    options,
    values,
    countLoaders,
    inView,
    loadWhenInView,
  } = props;
  const [t] = useTranslation('filters');

  const { loading, counts } = useLoadCount({
    useRequest: countLoaders.returnFlightArrivalTime,
    inView,
    loadWhenInView,
  });

  const { returnFlightArrivalAirport } = useGetAirports();

  const transformedOptions = useGetTransformedOptions(
    options.returnFlightArrivalTime,
    counts as IFilterCount[] | undefined
  );

  if (!options.returnFlightArrivalTime) return null;

  const flightChange = returnArrival =>
    onChange({
      ...values,
      returnFlightArrivalTime: returnArrival,
      prevChangedFilter: Options.returnFlightArrivalTime,
    });

  return (
    <TimeRangeInput
      title={`${t('flightArrivalTime.label')} ${
        returnFlightArrivalAirport?.name || ''
      }`}
      name="returnFlightArrivalTime"
      value={values.returnFlightArrivalTime}
      options={transformedOptions}
      testId="returnFlightArrivalTime"
      onChange={flightChange}
      className="flightTimesInput"
      loading={loading}
    />
  );
};

export default ReturnArrival;
