import { useRef, useState, EffectCallback } from 'react';
import { useIsomorphicLayoutEffect } from '@hotelplan/libs.hooks-react';

interface ITagBoxSummaryState {
  showLess: boolean;
  showMore: boolean;
}

export const initialState: ITagBoxSummaryState = {
  showLess: false,
  showMore: false,
};

export const showMoreState: ITagBoxSummaryState = {
  showLess: false,
  showMore: true,
};

export const showLessState: ITagBoxSummaryState = {
  showLess: true,
  showMore: false,
};

const useTagBoxListOverflowing = (
  tagsCount: number,
  containersHeight: number
) => {
  const [tagBoxSummaryState, setTagBoxSummaryState] = useState(initialState);

  const tagsList = useRef<HTMLDivElement>(null);
  const tagsContainer = useRef<HTMLDivElement>(null);

  useIsomorphicLayoutEffect(
    function handleTagBoxListOverflowingEffect(): ReturnType<EffectCallback> {
      const frameId = window.requestAnimationFrame(() => {
        if (!tagsList.current || !tagsContainer.current) {
          return;
        }

        const tagsListRect = tagsList.current.getBoundingClientRect();
        const tagsContainerRect = tagsContainer.current.getBoundingClientRect();
        if (tagsListRect.bottom > tagsContainerRect.bottom) {
          setTagBoxSummaryState(showMoreState);
        } else if (tagsListRect.height <= containersHeight) {
          setTagBoxSummaryState(initialState);
        }
      });

      return function cleanupAnimationFrame(): void {
        window.cancelAnimationFrame(frameId);
      };
    },
    [tagsCount]
  );

  const handleShowMore = (): void => setTagBoxSummaryState(showLessState);
  const handleShowLess = (): void => setTagBoxSummaryState(showMoreState);

  return {
    tagsList,
    tagsContainer,
    ...tagBoxSummaryState,
    handleShowMore,
    handleShowLess,
  };
};

export default useTagBoxListOverflowing;
