import { useTranslation } from 'next-i18next';
import React from 'react';
import { FilterOptions as Options } from 'components/domain/filters/Filters.constants';
import { IFilterCount } from 'components/domain/filters/Filters.types';
import {
  useGetAirports,
  useGetTransformedOptions,
} from 'components/domain/filters/search-filter-input/blocks/departure-arrival/departure-arrival.utils';
import { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import { useLoadCount } from 'components/domain/filters/search-filter-input/blocks/useLoadCount';
import TimeRangeInput from 'components/domain/filters/time-range-input/TimeRangeInput';

const DirectArrival: React.FC<TComponentProps> = (props: TComponentProps) => {
  const {
    onChange,
    options,
    values,
    countLoaders,
    inView,
    loadWhenInView,
  } = props;
  const [t] = useTranslation('filters');

  const { loading, counts } = useLoadCount({
    useRequest: countLoaders.directFlightArrivalTime,
    inView,
    loadWhenInView,
  });

  const { directFlightArrivalAirport } = useGetAirports();

  const transformedOptions = useGetTransformedOptions(
    options.directFlightArrivalTime,
    counts as IFilterCount[] | undefined
  );

  if (!options.directFlightArrivalTime) return null;

  const flightChange = directArrival =>
    onChange({
      ...values,
      directFlightArrivalTime: directArrival,
      prevChangedFilter: Options.directFlightArrivalTime,
    });

  return (
    <TimeRangeInput
      title={`${t('flightArrivalTime.label')} ${
        directFlightArrivalAirport?.name || ''
      }`}
      name="directFlightArrivalTime"
      value={values.directFlightArrivalTime}
      options={transformedOptions}
      testId="directFlightArrivalTime"
      onChange={flightChange}
      className="flightTimesInput"
      loading={loading}
    />
  );
};

export default DirectArrival;
