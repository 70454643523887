import React from 'react';
import FilterCheckBoxGroup from 'components/domain/filters/FilterCheckBoxGroup';
import { FilterOptions as Options } from 'components/domain/filters/Filters.constants';
import type { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import { useLoadCount } from 'components/domain/filters/search-filter-input/blocks/useLoadCount';

const Popular: React.FC<TComponentProps> = ({
  values,
  countLoaders,
  onChange,
  options,
  inView,
  loadWhenInView,
}: TComponentProps) => {
  const { loading, counts } = useLoadCount({
    useRequest: countLoaders.popularFilters,
    inView,
    loadWhenInView,
  });

  const items = options.popularFilters.map(option => ({
    ...option,
    caption: option.caption.replace('&shy;', ''),
  }));

  return (
    <FilterCheckBoxGroup
      loading={loading}
      value={values.popularFilters || []}
      options={items}
      groupId={Options.popularFilters}
      onChange={nextValue => {
        onChange({
          ...values,
          popularFilters: nextValue,
          prevChangedFilter: Options.popularFilters,
        });
      }}
      counts={counts}
    />
  );
};

export default Popular;
