import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export interface IRemovableTagProps {
  id?: string;
  label: React.ReactNode;
  verticalGap: number;
  tagHeight: number;
  onRemove?(): void;
  className?: string;
}

const RemovableTagWrapper = styled.span<{
  tagHeight: number;
  verticalGap: number;
}>(
  ({ tagHeight, verticalGap }) =>
    sx2CssThemeFn({
      display: 'flex',
      boxSizing: 'border-box',
      alignItems: 'center',
      height: `${tagHeight}px`,
      px: '8px',
      border: '1px solid',
      borderColor: 'borderColor',
      borderRadius: 'default',
      backgroundColor: 'background',
      mb: `${verticalGap}px`,
      mr: 2,
    }),
  ({ theme }) => theme?.tag?.removableTagWrapper || {}
);

const RemovableTagLabel = styled.span(
  sx2CssThemeFn({
    mr: 1,
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  })
);

const TagRemoveButton = styled(Button).attrs({
  variant: 'iconBtn',
  icon: { name: 'close-outlined', size: 'sm' },
})({});

const RemovableTag: React.FC<IRemovableTagProps> = ({
  label,
  onRemove,
  className,
  verticalGap,
  tagHeight,
}) => {
  const [t] = useTranslation();

  return (
    <RemovableTagWrapper
      className={`removableTag ${className}`}
      data-id={`removableTag_${label}`}
      tagHeight={tagHeight}
      verticalGap={verticalGap}
    >
      <RemovableTagLabel className={'removableTagLabel'}>
        {label}
      </RemovableTagLabel>
      <TagRemoveButton onClick={onRemove}>{t('common:delete')}</TagRemoveButton>
    </RemovableTagWrapper>
  );
};

export default RemovableTag;
