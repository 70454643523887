import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FilterOptions as Options } from 'components/domain/filters/Filters.constants';
import type { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import HotelplanRating from 'components/domain/filters/search-filter-input/blocks/ratings/HotelplanRating';
import TripAdviserRating from 'components/domain/filters/search-filter-input/blocks/ratings/TripAdviserRating';
import { useLoadCount } from 'components/domain/filters/search-filter-input/blocks/useLoadCount';

const RatingFilterWrap = styled.div(sx2CssThemeFn({ py: [3, '11px'] }));

const createOptions = source =>
  (source || []).map(option => ({ value: option.id, label: option.caption }));

const Ratings: React.FC<TComponentProps> = ({
  values,
  countLoaders,
  onChange,
  options,
  inView,
  loadWhenInView,
}: TComponentProps) => {
  const { loading: hpLoading, counts: hpCounts } = useLoadCount({
    useRequest: countLoaders.hotelplanRating,
    inView,
    loadWhenInView,
  });

  const { loading: taLoading, counts: taCounts } = useLoadCount({
    useRequest: countLoaders.tripAdvisorRating,
    inView,
    loadWhenInView,
  });

  const hpOptions = createOptions(options.hotelplanRating);
  const taOptions = createOptions(options.tripAdvisorRating);

  return (
    <RatingFilterWrap>
      <HotelplanRating
        counts={hpCounts}
        loading={hpLoading}
        hotelPlanRatingValue={values.hotelplanRating}
        hotelPlanRatingOnChange={nextValue => {
          onChange({
            ...values,
            hotelplanRating: nextValue,
            prevChangedFilter: Options.hotelplanRating,
          });
        }}
        hotelPlanRatingOptions={hpOptions}
      />

      <TripAdviserRating
        counts={taCounts}
        loading={taLoading}
        tripAdvisorRatingValue={values.tripAdvisorRating}
        tripAdvisorRatingOptions={taOptions}
        tripAdvisorRatingOnChange={nextValue => {
          onChange({
            ...values,
            tripAdvisorRating: nextValue,
            prevChangedFilter: Options.tripAdvisorRating,
          });
        }}
      />
    </RatingFilterWrap>
  );
};

export default React.memo(Ratings);
