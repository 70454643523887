import React from 'react';
import { useGetSingleRangeInputTooltip } from '@hotelplan/components.common.double-range';
import { FilterOptions as Options } from 'components/domain/filters/Filters.constants';
import type { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import TimeDurationRangeInput from 'components/domain/time-duration-range-input/TimeDurationRangeInput';

const FlightDuration: React.FC<TComponentProps> = ({
  values,
  onChange,
}: TComponentProps) => {
  const getTooltip = useGetSingleRangeInputTooltip();

  const value = values.maxFlightDuration
    ? parseInt(values.maxFlightDuration, 10)
    : null;

  return (
    <TimeDurationRangeInput
      value={value}
      onChange={nextValue => {
        onChange({
          ...values,
          maxFlightDuration: nextValue?.toString() || null,
          prevChangedFilter: Options.maxFlightDuration,
        });
      }}
      loading={false}
      getTooltip={getTooltip}
    />
  );
};

export default FlightDuration;
