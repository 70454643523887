import React from 'react';
import styled from 'styled-components';
import { PlaceholderLoader } from '@hotelplan/components.common.placeholder-loader';
import { getSVGRoundedRectShapeString } from 'components/domain/svg/SVG.utils';
import {
  tagsContainerCss,
  tagsListCss,
  tagWrapperCss,
} from 'components/domain/tag/TagBox.styles';

interface ITagBoxTagRectProps {
  x: number;
  y: number;
  width: number;
}

export const SVGTagBoxTagRect: React.FC<ITagBoxTagRectProps> = ({
  width,
  x,
  y,
}) => {
  return (
    <path
      d={
        // The whole tag rounded rect
        getSVGRoundedRectShapeString({
          width,
          height: 35,
          x,
          y,
          r: 8,
        }) +
        // Circle subtraction (remove buttons)
        getSVGRoundedRectShapeString({
          width: 16,
          height: 16,
          x: x + width - 26,
          y: y + Math.ceil((35 - 16) / 2),
          r: 8,
        })
      }
      clipRule="evenodd"
    />
  );
};

const TagBoxWrapper = styled.div(tagWrapperCss);
const TagsContainer = styled.div({
  ...tagsContainerCss,
  height: '43px',
});
const TagsList = styled.div(tagsListCss, {
  width: '100%',
});

const TagBoxSkeleton: React.FC<
  React.HTMLAttributes<HTMLDivElement>
> = divProps => {
  const WIDTH = 330;
  const HEIGHT = 43;
  const PADDING = 8;

  return (
    <TagBoxWrapper {...divProps}>
      <TagsContainer>
        <TagsList>
          <PlaceholderLoader
            uid="tagBoxTagsSkeleton"
            width={WIDTH}
            height={HEIGHT}
            style={{ width: '100%', height: '100%' }}
          >
            <SVGTagBoxTagRect x={0} y={0} width={80} />
            <SVGTagBoxTagRect x={80 + PADDING} y={0} width={110} />
            <SVGTagBoxTagRect
              x={80 + PADDING + 110 + PADDING}
              y={0}
              width={90}
            />
          </PlaceholderLoader>
        </TagsList>
      </TagsContainer>
    </TagBoxWrapper>
  );
};

export default TagBoxSkeleton;
