import React from 'react';
import styled from 'styled-components';
import { RadioButton } from '@hotelplan/components.common.buttons';
import { RadioGroupInput } from '@hotelplan/components.common.radio-group';
import {
  FilterOptionWrapper,
  FilterOptionContent,
} from 'components/domain/filters/filter-option';
import { FiltersRadioControl } from 'components/domain/filters/Filters.styles';

const TimeRangeRadioGroup = styled(RadioGroupInput)(
  ({ theme: { space, colors } }) => ({
    margin: 0,
    '.fieldset-title': {
      marginLeft: space[3],
      color: colors.darkGray,
    },
  })
);

interface ITimeRangeInputProps {
  name: string;
  value: string | null;
  title?: string;
  testId?: string;
  options: Array<{
    label: React.ReactNode;
    count: number;
    id: string;
    disabled?: boolean;
  }>;
  onChange(nextValue: string | null): void;
  loading: boolean;
  className?: string;
}

const TimeRangeInput: React.FC<ITimeRangeInputProps> = props => {
  const { name, title, value, options, onChange, className, loading } = props;

  return (
    <TimeRangeRadioGroup
      renderCustomOption={option => {
        return (
          <FilterOptionWrapper active={option.checked}>
            <RadioButton
              {...option}
              renderCustomControl={() => (
                <FiltersRadioControl
                  className="customCheckboxInput radio-control"
                  checked={option.checked}
                  disabled={option.disabled}
                />
              )}
            />
          </FilterOptionWrapper>
        );
      }}
      name={name}
      title={title}
      value={value || undefined}
      options={options.map((option, i) => ({
        content: (
          <FilterOptionContent
            uid={`timeRange_${i}`}
            text={option.label}
            count={option.count}
            disabled={option.disabled}
            loading={loading}
          />
        ),
        value: option.id,
        disabled: option.disabled,
      }))}
      onChange={onChange}
      className={className}
      testId={props.testId}
    />
  );
};

export default TimeRangeInput;
