import type { CSSObject } from 'styled-components';
import { sx2CssTheme } from '@hotelplan/util.theme.sxc';

export const tagsContainerCss: CSSObject = {
  display: 'flex',
  overflow: 'hidden',
};

export const tagWrapperCss = ({ theme }) =>
  sx2CssTheme(theme, {
    position: 'relative',
    p: 3,
    pb: 0,
    borderTop: [null, '1px solid'],
    borderTopColor: [null, 'borderColor'],
    borderBottom: '8px solid white',
  });

export const tagsListCss = ({ theme }) =>
  sx2CssTheme(theme, {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    height: '100%',
    pr: 3,
  });
