import { useTranslation } from 'next-i18next';
import React from 'react';
import { FilterOptions as Options } from 'components/domain/filters/Filters.constants';
import { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import { useTimeOptions } from 'components/domain/filters/search-filter-input/blocks/flight-time/flight-time';
import { useLoadCount } from 'components/domain/filters/search-filter-input/blocks/useLoadCount';
import TimeRangeInput from 'components/domain/filters/time-range-input/TimeRangeInput';

const DepartureTime: React.FC<TComponentProps> = (props: TComponentProps) => {
  const {
    onChange,
    options,
    values,
    countLoaders,
    inView,
    loadWhenInView,
  } = props;
  const [t] = useTranslation('filters');

  const { loading, counts } = useLoadCount({
    useRequest: countLoaders.directFlightDepartureTime,
    inView,
    loadWhenInView,
  });

  const departureTimeOptions = useTimeOptions(
    counts,
    options.directFlightDepartureTime
  );

  if (!options.directFlightDepartureTime) return null;

  const flightChange = directDeparture =>
    onChange({
      ...values,
      directFlightDepartureTime: directDeparture,
      prevChangedFilter: Options.directFlightDepartureTime,
    });

  return (
    <TimeRangeInput
      title={t('departureTime')}
      name="departureTime"
      value={values.directFlightDepartureTime}
      options={departureTimeOptions}
      testId="departureTime"
      onChange={flightChange}
      className="flight-departure-time"
      loading={loading}
    />
  );
};

export default DepartureTime;
