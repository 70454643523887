import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Image } from '@hotelplan/components.common.image';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { useTrackFiltersExceptions } from '@hotelplan/libs.tracking';
import type {
  TUseCounts,
  IFilterCount,
} from 'components/domain/filters/Filters.types';

interface ITotalFilterProps {
  useCounts: TUseCounts<IFilterCount>;
  haveSelectedFilters: boolean;
}

const ButtonWrapper = styled.div<{ haveSelectedFilters: boolean }>(
  ({ theme: { space }, haveSelectedFilters }) => ({
    padding: space[3],
    paddingTop: haveSelectedFilters ? 0 : space[3],
  })
);

const TotalFilterButton = styled(BsButton)({
  width: '100%',
  borderRadius: '6px',
  '.total-filter-button-image': {
    width: '50px',
    height: '16px',
    verticalAlign: 'middle',
  },
});

const TotalFilter: React.FC<ITotalFilterProps> = ({
  useCounts,
  haveSelectedFilters,
}) => {
  const [t] = useTranslation('filters');

  const { counts, error, loading } = useCounts();
  useTrackFiltersExceptions(counts?.count, loading, error);

  const { count } = counts || {};

  return (
    <ButtonWrapper haveSelectedFilters={haveSelectedFilters}>
      <TotalFilterButton
        data-id="applyButton"
        type="submit"
        disabled={loading || !count}
      >
        {loading ? (
          <Image
            resized={[]}
            src="/images/spinner-button.svg"
            alt="LOADING"
            className="total-filter-button-image"
          />
        ) : (
          <span className="totalFilterCounter">{count}</span>
        )}{' '}
        {t('filters:viewResults.button', { count })}
      </TotalFilterButton>
    </ButtonWrapper>
  );
};

export default TotalFilter;
