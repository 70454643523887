import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Button } from '@hotelplan/components.common.buttons';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import RemovableTag, {
  IRemovableTagProps,
} from 'components/domain/tag/RemovableTag';
import {
  tagsContainerCss,
  tagsListCss,
  tagWrapperCss,
} from 'components/domain/tag/TagBox.styles';
import useTagBoxListOverflowing from 'components/domain/tag/useTagBoxListOverflowing';

const TagBoxWrapper = styled.div(tagWrapperCss);

const TagsContainer = styled.div.attrs({
  'data-id': `tagsSummary`,
})<{ maxHeight?: number; expanded: boolean }>(
  {
    display: 'flex',
    ...tagsContainerCss,
  },
  ({ maxHeight, expanded }) => ({
    maxHeight:
      expanded || typeof maxHeight === 'undefined' ? 'none' : `${maxHeight}px`,
  })
);

const TagsList = styled.div<{ expanded: boolean }>(
  tagsListCss,
  ({ expanded }) =>
    expanded
      ? sx2CssThemeFn({
          overflow: [`scroll`, 'initial'],
        })
      : null
);

const TagBoxButton = styled(Button).attrs({
  variant: 'iconBtn',
  testId: `showMoreTags`,
})(({ theme: { space } }) => ({
  position: 'absolute',
  right: space[4],
  bottom: '5px',
  transform: 'rotate(90deg)',
}));

const TagBoxChevron = styled(TagBoxButton).attrs<{
  expanded: boolean;
}>(({ expanded }) => ({
  icon: { name: 'chevron-left', size: 'xs' },
  testId: expanded ? 'showLessTags' : 'showMoreTags',
}))<{ expanded: boolean }>(({ expanded }) => ({
  transform: expanded ? 'rotate(270deg)' : undefined,
}));

interface ITagBoxProps {
  lines?: number;
  tags: Omit<IRemovableTagProps, 'tagHeight' | 'verticalGap'>[];
  className?: string;
  tagHeight?: number;
  verticalGap?: number;
}

const DEFAULT_TAG_HEIGHT = 35;
const DEFAULT_VERTICAL_GAP = 8;

const TagBox: React.FC<ITagBoxProps> = props => {
  const {
    tags,
    className,
    lines = 0,
    tagHeight = DEFAULT_TAG_HEIGHT,
    verticalGap = DEFAULT_VERTICAL_GAP,
  } = props;
  const [t] = useTranslation();

  const tagsCount = tags.length;

  const tagsContainerHeight = (tagHeight + verticalGap) * lines;

  const {
    tagsContainer,
    tagsList,
    showLess: expanded,
    showMore,
    handleShowLess,
    handleShowMore,
  } = useTagBoxListOverflowing(tagsCount, tagsContainerHeight);

  if (tagsCount === 0) return null;

  return (
    <TagBoxWrapper className={className}>
      <TagsContainer
        ref={tagsContainer}
        expanded={expanded}
        maxHeight={tagsContainerHeight}
      >
        <TagsList ref={tagsList} expanded={expanded}>
          {tags.map(({ label, id, onRemove }) => {
            return (
              <RemovableTag
                key={id}
                label={label}
                tagHeight={tagHeight}
                verticalGap={verticalGap}
                onRemove={onRemove}
              />
            );
          })}
        </TagsList>
        {(showMore || expanded) && (
          <TagBoxChevron
            className="tag-box-chevron"
            expanded={expanded}
            onClick={expanded ? handleShowLess : handleShowMore}
          >
            {expanded ? t('Show less') : t('Show more')}
          </TagBoxChevron>
        )}
      </TagsContainer>
    </TagBoxWrapper>
  );
};

export default TagBox;
