import React from 'react';
import styled from 'styled-components';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const MapButtonContent = styled.span(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.LINK_LARGE,
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
    alignItems: 'center',
    letterSpacing: '0.7px',
    textDecoration: 'underline',
    backgroundImage: 'url("/images/Map-mock.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '60px',
  })
);

const MapButtonDesktop = styled(BsButtonWithIcon).attrs(({ children }) => ({
  icon: { name: 'location' },
  'data-id': `map-btn`,
  variant: E_BUTTON_TYPE.CLEAR_CTA,
  children: <MapButtonContent>{children}</MapButtonContent>,
}))(
  sx2CssThemeFn({
    backgroundColor: 'white',
    mb: 3,
    width: '100%',
    color: 'black',
    p: 0,
    textAlign: 'left',
    boxShadow: 'cardShadow',
    display: 'flex',

    '.icon': {
      width: '35px',
      height: '35px',
      my: '12px',
      mx: '20px',
    },

    ':hover:not([disabled]),:focus-visible:not([disabled]),:focus:not([disabled])': {
      backgroundColor: 'white',
      border: 'none',
    },
  })
);

export default MapButtonDesktop;
