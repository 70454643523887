import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import {
  MixedModal,
  ICommonModalProps,
} from '@hotelplan/components.common.modals';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { useDestinationStack } from 'components/domain/destinationStack/DestinationStackContext';

type ISRLMapModalProps = Pick<ICommonModalProps, 'show' | 'onClose'> & {
  name?: string;
  label?: string;
} & PropsWithChildren<{}>;

const BaseSRLMapModal = styled(MixedModal)(
  sx2CssThemeFn({
    '.modal-wrapper': {
      width: ['100%', 'calc(100% - 60px)'],
      maxWidth: [null, '1250px'],
      height: [null, '95%'],
      borderRadius: ['roundedTop', '0px'],
      position: 'relative',
    },
    '.modal-body': {
      pb: 0,
    },
  })
);

const SRLMapModal: React.FC<ISRLMapModalProps> = props => {
  const [t] = useTranslation('srl');
  const {
    name = 'srlMapModal',
    label = t('srl:mapModal.label') as string,
    show,
    onClose,
    children,
  } = props;

  const { reset } = useDestinationStack();

  function onCloseHandler() {
    onClose?.();
    reset();
  }

  const modalProps: ICommonModalProps = {
    name,
    label,
    show,
    onClose: onCloseHandler,
  };

  return <BaseSRLMapModal {...modalProps}>{children}</BaseSRLMapModal>;
};

export default SRLMapModal;
