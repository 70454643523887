import without from 'lodash/without';
import { useTranslation, TFunction } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { priceOptions } from '@hotelplan/components.common.double-range';
import { Icon } from '@hotelplan/components.common.icon';
import type { ICoordinates } from '@hotelplan/components.common.map-pin';
import { Rating } from '@hotelplan/components.common.rating';
import { flightDurationOptions } from '@hotelplan/constants';
import { FiltersEventType, trackFilters } from '@hotelplan/libs.tracking';
import type {
  IFiltersFormState,
  IFilterOptions,
  IFilterOption,
} from 'components/domain/filters/Filters.types';
import { OptionsIds } from 'components/domain/filters/Filters.types';
import { useGetAirports } from 'components/domain/filters/search-filter-input/blocks/departure-arrival/departure-arrival.utils';
import { convertMinutesToDurationHours } from 'components/domain/filters/search-filter-input/blocks/stop-duration/FlightStopoverDurationFilter';
import TagBox from 'components/domain/tag/TagBox';

// @TODO Maybe move to some constants files?
enum RatingIcons {
  HP = 'hotelplanbewertung',
  TA = 'tripadvisorone',
}

const FilterTagRating = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const FilterTagRatingItem = styled(Rating)(({ theme: { space, colors } }) => ({
  color: colors.primary,
  marginLeft: space[1],
  '.filter-tag-rating-icon': {
    marginRight: space[1],
  },
}));

export function createRatingLabel(
  t: TFunction,
  value: number,
  iconName: string
): React.ReactNode {
  return (
    <FilterTagRating>
      {t('least.label')}
      <FilterTagRatingItem
        rating={value}
        ratingContent={
          <Icon name={iconName} className="filter-tag-rating-icon" />
        }
      />
    </FilterTagRating>
  );
}

interface ITag {
  id: string;
  label: React.ReactNode;
  nextFiltersState: IFiltersFormState;
  onRemove: () => void;
}

const addProductFeatureTags = (
  features: string[] | null,
  options: IFilterOption[] | null,
  nextFilterStateProvider: (string) => IFiltersFormState,
  tags: ITag[]
) => {
  if (features && options) {
    options
      .filter(({ id }) => features.includes(id))
      .forEach(featureOption => {
        const id = featureOption.id;
        tags.push({
          id: `feature-${id}`,
          label: (
            <span dangerouslySetInnerHTML={{ __html: featureOption.caption }} />
          ),
          nextFiltersState: nextFilterStateProvider(id),
          onRemove: () =>
            trackFilters({
              type: FiltersEventType.FILTERS_REMOVE,
              payload: { label: id },
            }),
        });
      });
  }
};

interface IFilterTagsProps {
  filters: IFiltersFormState;
  options: IFilterOptions;
  onChange(nextFilters: IFiltersFormState): void;
  lines: number;
  className?: string;
}

function formatDegrees(degreeMinutes: number) {
  const [degreesString, minutesString] = `${
    Math.round(degreeMinutes * 100) / 100
  }`.split('.');
  const degrees = parseInt(degreesString, 10);
  const minutes = parseInt(minutesString.slice(0, 2), 10);
  return `${degrees}° ${minutes}'`;
}

function formatLatLong(point: ICoordinates): string {
  const { latitude, longitude } = point;
  return `${formatDegrees(latitude)},${formatDegrees(longitude)}`;
}

export const FILTER_TAG_HEIGHT = 35;

const FilterTags: React.FC<IFilterTagsProps> = props => {
  const { filters, options, onChange, className, lines } = props;
  const [t] = useTranslation('filters');

  const {
    roomTypes,
    boardTypes,
    maxPricePerPerson,
    hotelplanRating,
    tripAdvisorRating,
    departureAirports,
    flightStopOver,
    maxFlightDuration,
    flightStopOverDuration,
    flightAirlines,
    directFlightDepartureTime,
    directFlightArrivalTime,
    returnFlightDepartureTime,
    returnFlightArrivalTime,
    departureWeekdays,
    arrivalWeekdays,
    arrivalAirports,
    minPrice,
    productCode,
    provider,
    radius,
    environmentFeatures,
    distanceFeatures,
    cultureFeatures,
    wellnessFeatures,
    childrenFeatures,
    sportFeatures,
    beachFeatures,
    mainFeatures,
    hotelFeatures,
  } = filters;

  const {
    roomTypes: roomTypeOptions,
    boardTypes: boardTypeOptions,
    hotelplanRating: hotelplanRatingOptions,
    tripAdvisorRating: tripAdvisorRatingOptions,
    departureAirports: departureAirportOptions,
    flightStopOver: stopOversOptions,
    flightAirlines: flightAirlinesOptions,
    directFlightDepartureTime: directFlightDepartureTimeOptions,
    directFlightArrivalTime: directFlightArrivalTimeOptions,
    returnFlightDepartureTime: returnFlightDepartureTimeOptions,
    returnFlightArrivalTime: returnFlightArrivalTimeOptions,
    departureWeekdays: departureWeekdaysOptions,
    arrivalWeekdays: arrivalWeekdaysOptions,
    arrivalAirports: arrivalAirportsOptions,
    productCode: productCodeOptions,
    provider: providerOptions,
    environmentFeatures: environmentFeaturesOptions,
    distanceFeatures: distanceFeaturesOptions,
    cultureFeatures: cultureFeaturesOptions,
    wellnessFeatures: wellnessFeaturesOptions,
    childrenFeatures: childrenFeaturesOptions,
    sportFeatures: sportFeaturesOptions,
    beachFeatures: beachFeaturesOptions,
    mainFeatures: mainFeaturesOptions,
    hotelFeatures: hotelFeaturesOptions,
  } = options;

  const {
    directFlightDepartureAirport,
    directFlightArrivalAirport,
    returnFlightDepartureAirport,
    returnFlightArrivalAirport,
  } = useGetAirports();

  const tags: ITag[] = [];

  addProductFeatureTags(
    mainFeatures?.filter(pf => pf !== OptionsIds.allInclusive),
    mainFeaturesOptions,
    id => ({
      ...filters,
      mainFeatures: without(mainFeatures, id),
    }),
    tags
  );

  addProductFeatureTags(
    environmentFeatures,
    environmentFeaturesOptions,
    id => ({
      ...filters,
      environmentFeatures: without(environmentFeatures, id),
    }),
    tags
  );
  addProductFeatureTags(
    distanceFeatures,
    distanceFeaturesOptions,
    id => ({
      ...filters,
      distanceFeatures: without(distanceFeatures, id),
    }),
    tags
  );
  addProductFeatureTags(
    cultureFeatures,
    cultureFeaturesOptions,
    id => ({
      ...filters,
      cultureFeatures: without(cultureFeatures, id),
    }),
    tags
  );
  addProductFeatureTags(
    wellnessFeatures,
    wellnessFeaturesOptions,
    id => ({
      ...filters,
      wellnessFeatures: without(wellnessFeatures, id),
    }),
    tags
  );
  addProductFeatureTags(
    childrenFeatures,
    childrenFeaturesOptions,
    id => ({
      ...filters,
      childrenFeatures: without(childrenFeatures, id),
    }),
    tags
  );
  addProductFeatureTags(
    sportFeatures,
    sportFeaturesOptions,
    id => ({
      ...filters,
      sportFeatures: without(sportFeatures, id),
    }),
    tags
  );
  addProductFeatureTags(
    beachFeatures,
    beachFeaturesOptions,
    id => ({
      ...filters,
      beachFeatures: without(beachFeatures, id),
    }),
    tags
  );
  addProductFeatureTags(
    hotelFeatures,
    hotelFeaturesOptions,
    id => ({
      ...filters,
      hotelFeatures: without(hotelFeatures, id),
    }),
    tags
  );

  if (roomTypes && roomTypeOptions) {
    roomTypeOptions
      .filter(({ id }) => roomTypes.includes(id))
      .forEach(option => {
        tags.push({
          id: `room-${option.id}`,
          label: option.caption,
          nextFiltersState: {
            ...filters,
            roomTypes: without(roomTypes, option.id),
            prevChangedFilter: 'roomTypes',
          },
          onRemove: () =>
            trackFilters({
              type: FiltersEventType.FILTERS_REMOVE,
              payload: { label: option.id },
            }),
        });
      });
  }

  if (boardTypes && boardTypeOptions) {
    boardTypeOptions
      .filter(({ id }) => boardTypes.includes(id))
      .forEach(option => {
        tags.push({
          id: `board-${option.id}`,
          label: option.caption,
          nextFiltersState: {
            ...filters,
            boardTypes: without(boardTypes, option.id),
            prevChangedFilter: 'boardTypes',
          },
          onRemove: () =>
            trackFilters({
              type: FiltersEventType.FILTERS_REMOVE,
              payload: { label: option.id },
            }),
        });
      });
  }

  if (maxPricePerPerson) {
    const priceOption = priceOptions.find(
      ({ value }) => value === parseInt(maxPricePerPerson, 10)
    );

    if (priceOption) {
      tags.push({
        id: `price-${priceOption.value}`,
        label: `${t('max')}. ${priceOption.caption}`,
        nextFiltersState: {
          ...filters,
          maxPricePerPerson: null,
          prevChangedFilter: 'maxPricePerPerson',
        },
        onRemove: () =>
          trackFilters({
            type: FiltersEventType.FILTERS_REMOVE,
            payload: { label: priceOption.caption },
          }),
      });
    }
  }

  if (maxFlightDuration) {
    const durationOption = flightDurationOptions.find(
      ({ value }) => value === parseInt(maxFlightDuration, 10)
    );

    if (durationOption) {
      tags.push({
        id: `flightDuration-${durationOption.value}`,
        label: `${t('max')}. ${durationOption.caption}`,
        nextFiltersState: {
          ...filters,
          maxFlightDuration: null,
          prevChangedFilter: 'maxFlightDuration',
        },
        onRemove: () =>
          trackFilters({
            type: FiltersEventType.FILTERS_REMOVE,
            payload: { label: durationOption.caption },
          }),
      });
    }
  }

  if (hotelplanRating && hotelplanRatingOptions) {
    const hpRatingOption = hotelplanRatingOptions.find(
      option => option.id === hotelplanRating
    );
    const ratingNumber = /(\d+)/.exec(hpRatingOption?.id || '')?.[0];

    if (hpRatingOption && ratingNumber) {
      tags.push({
        id: `hp-${hpRatingOption.id}`,
        label: createRatingLabel(t, parseInt(ratingNumber), RatingIcons.HP),
        nextFiltersState: {
          ...filters,
          hotelplanRating: null,
          prevChangedFilter: 'hotelplanRating',
        },
        onRemove: () =>
          trackFilters({
            type: FiltersEventType.FILTERS_REMOVE,
            payload: { label: hpRatingOption.id },
          }),
      });
    }
  }

  if (tripAdvisorRating && tripAdvisorRatingOptions) {
    const tripAdvisorRatingOption = tripAdvisorRatingOptions.find(
      option => option.id === tripAdvisorRating
    );
    const ratingNumber = /(\d+)/.exec(tripAdvisorRatingOption?.id || '')?.[0];

    if (tripAdvisorRatingOption && ratingNumber) {
      tags.push({
        id: `ta-${tripAdvisorRatingOption.id}`,
        label: createRatingLabel(t, parseInt(ratingNumber), RatingIcons.TA),
        nextFiltersState: {
          ...filters,
          tripAdvisorRating: null,
          prevChangedFilter: 'tripAdvisorRating',
        },
        onRemove: () =>
          trackFilters({
            type: FiltersEventType.FILTERS_REMOVE,
            payload: { label: tripAdvisorRatingOption.id },
          }),
      });
    }
  }

  if (departureAirports && departureAirportOptions) {
    departureAirportOptions
      .filter(({ id }) => departureAirports.includes(id))
      .forEach(option => {
        tags.push({
          id: `airport-${option.id}`,
          label: option.caption,
          nextFiltersState: {
            ...filters,
            departureAirports: without(departureAirports, option.id),
            prevChangedFilter: 'departureAirports',
          },
          onRemove: () =>
            trackFilters({
              type: FiltersEventType.FILTERS_REMOVE,
              payload: { label: option.id },
            }),
        });
      });
  }

  if (stopOversOptions) {
    const stopOverOption = stopOversOptions.find(
      option => option.id === flightStopOver
    );

    const ANY_VALUE = 'ANY_STOPS';

    if (stopOverOption && stopOverOption.id !== ANY_VALUE) {
      tags.push({
        id: `stop-${stopOverOption.id}`,
        label: stopOverOption.caption,
        nextFiltersState: {
          ...filters,
          flightStopOver: null,
          prevChangedFilter: 'flightStopOver',
        },
        onRemove: () =>
          trackFilters({
            type: FiltersEventType.FILTERS_REMOVE,
            payload: { label: stopOverOption.id },
          }),
      });
    }
  }

  if (flightStopOverDuration) {
    const durationFromOption = convertMinutesToDurationHours(
      flightStopOverDuration.minSelected
    );
    const durationToOption = convertMinutesToDurationHours(
      flightStopOverDuration.maxSelected
    );

    const stopoverDurationLabel =
      durationFromOption !== durationToOption
        ? `${t(
            'flightStopOverDuration.label'
          )}: ${durationFromOption} - ${durationToOption}`
        : `${t('flightStopOverDuration.label')}: ${durationFromOption}`;

    tags.push({
      id: `flight-stopover-dur`,
      label: stopoverDurationLabel,
      nextFiltersState: {
        ...filters,
        flightStopOverDuration: null,
        prevChangedFilter: 'flightStopOverDuration',
      },
      onRemove: () =>
        trackFilters({
          type: FiltersEventType.FILTERS_REMOVE,
          payload: { label: stopoverDurationLabel },
        }),
    });
  }

  if (flightAirlines && flightAirlinesOptions) {
    flightAirlinesOptions
      .filter(({ id }) => flightAirlines.includes(id))
      .forEach(option => {
        tags.push({
          id: `airline-${option.id}`,
          label: option.caption,
          nextFiltersState: {
            ...filters,
            flightAirlines: without(flightAirlines, option.id),
            prevChangedFilter: 'flightAirlines',
          },
          onRemove: () =>
            trackFilters({
              type: FiltersEventType.FILTERS_REMOVE,
              payload: { label: option.id },
            }),
        });
      });
  }

  if (departureWeekdaysOptions && departureWeekdays) {
    departureWeekdaysOptions
      .filter(({ id }) => departureWeekdays.includes(id))
      .forEach(option => {
        tags.push({
          id: `departure-weekday-${option.id}`,
          label: option.caption,
          nextFiltersState: {
            ...filters,
            departureWeekdays: without(departureWeekdays, option.id),
          },
          onRemove: () =>
            trackFilters({
              type: FiltersEventType.FILTERS_REMOVE,
              payload: { label: option.id },
            }),
        });
      });
  }

  if (arrivalWeekdaysOptions && arrivalWeekdays) {
    arrivalWeekdaysOptions
      .filter(({ id }) => arrivalWeekdays.includes(id))
      .forEach(option => {
        tags.push({
          id: `arrival-weekday-${option.id}`,
          label: option.caption,
          nextFiltersState: {
            ...filters,
            arrivalWeekdays: without(arrivalWeekdays, option.id),
          },
          onRemove: () =>
            trackFilters({
              type: FiltersEventType.FILTERS_REMOVE,
              payload: { label: option.id },
            }),
        });
      });
  }

  if (arrivalAirportsOptions && arrivalAirports) {
    arrivalAirportsOptions
      .filter(({ id }) => arrivalAirports.includes(id))
      .forEach(option => {
        tags.push({
          id: `arrival-airport-${option.id}`,
          label: option.caption,
          nextFiltersState: {
            ...filters,
            arrivalAirports: without(arrivalAirports, option.id),
          },
          onRemove: () =>
            trackFilters({
              type: FiltersEventType.FILTERS_REMOVE,
              payload: { label: option.id },
            }),
        });
      });
  }

  // FIXME: it might be handled differently...
  if (minPrice) {
    const priceOption = priceOptions.find(
      ({ value }) => value === parseInt(minPrice, 10)
    );

    if (priceOption) {
      tags.push({
        id: `min-price-${priceOption.value}`,
        label: priceOption.caption,
        nextFiltersState: {
          ...filters,
          minPrice: null,
        },
        onRemove: () =>
          trackFilters({
            type: FiltersEventType.FILTERS_REMOVE,
            payload: { label: priceOption.caption },
          }),
      });
    }
  }

  if (productCode && productCodeOptions) {
    tags.push({
      id: `product-code-${productCode}`,
      label: productCodeOptions.caption,
      nextFiltersState: {
        ...filters,
        productCode: null,
      },
      onRemove: () =>
        trackFilters({
          type: FiltersEventType.FILTERS_REMOVE,
          payload: { label: productCodeOptions.caption },
        }),
    });
  }

  if (provider && providerOptions) {
    tags.push({
      id: `provider-${provider}`,
      label: providerOptions.caption,
      nextFiltersState: {
        ...filters,
        provider: null,
      },
      onRemove: () =>
        trackFilters({
          type: FiltersEventType.FILTERS_REMOVE,
          payload: { label: providerOptions.caption },
        }),
    });
  }

  if (directFlightArrivalTime && directFlightArrivalTimeOptions) {
    const directFlightArrivalTimeOption = directFlightArrivalTimeOptions.find(
      option => directFlightArrivalTime === option.id
    );

    const ANY_VALUE = 'DIR_ARR_ANY_TIME';

    if (
      directFlightArrivalTimeOption &&
      directFlightArrivalTimeOption.id !== ANY_VALUE
    ) {
      tags.push({
        id: `direct-flight-arrival-time-${directFlightArrivalTimeOption.id}`,
        label: t('directFlightArrival.time.label', {
          airport: directFlightArrivalAirport?.iata || '',
          time: directFlightArrivalTimeOption.caption,
        }),
        nextFiltersState: {
          ...filters,
          directFlightArrivalTime: null,
          prevChangedFilter: 'directFlightArrivalTime',
        },
        onRemove: () =>
          trackFilters({
            type: FiltersEventType.FILTERS_REMOVE,
            payload: { label: directFlightArrivalTimeOption.id },
          }),
      });
    }
  }

  if (directFlightDepartureTime && directFlightDepartureTimeOptions) {
    const directFlightDepartureTimeOption = directFlightDepartureTimeOptions.find(
      option => directFlightDepartureTime === option.id
    );

    const ANY_VALUE = 'DIR_DEP_ANY_TIME';

    if (
      directFlightDepartureTimeOption &&
      directFlightDepartureTimeOption.id !== ANY_VALUE
    ) {
      tags.push({
        id: `direct-flight-departure-time-${directFlightDepartureTimeOption.id}`,
        label: t(
          `directFlightDeparture.time.${
            directFlightDepartureAirport?.iata ? 'label' : 'label.short'
          }`,
          {
            airport: directFlightDepartureAirport?.iata,
            time: directFlightDepartureTimeOption.caption,
          }
        ),
        nextFiltersState: {
          ...filters,
          directFlightDepartureTime: null,
          prevChangedFilter: 'directFlightDepartureTime',
        },
        onRemove: () =>
          trackFilters({
            type: FiltersEventType.FILTERS_REMOVE,
            payload: { label: directFlightDepartureTimeOption.id },
          }),
      });
    }
  }

  if (returnFlightDepartureTime && returnFlightDepartureTimeOptions) {
    const returnFlightDepartureTimeOption = returnFlightDepartureTimeOptions.find(
      option => option.id === returnFlightDepartureTime
    );

    const ANY_VALUE = 'RET_DEP_ANY_TIME';

    if (
      returnFlightDepartureTimeOption &&
      returnFlightDepartureTimeOption.id !== ANY_VALUE
    ) {
      tags.push({
        id: `return-departure-flight-time-${returnFlightDepartureTimeOption.id}`,
        label: t(
          `returnFlightDeparture.time.${
            returnFlightDepartureAirport?.iata ? 'label' : 'label.short'
          }`,
          {
            airport: returnFlightDepartureAirport?.iata,
            time: returnFlightDepartureTimeOption.caption,
          }
        ),
        nextFiltersState: {
          ...filters,
          returnFlightDepartureTime: null,
          prevChangedFilter: 'returnFlightDepartureTime',
        },
        onRemove: () =>
          trackFilters({
            type: FiltersEventType.FILTERS_REMOVE,
            payload: { label: returnFlightDepartureTimeOption.id },
          }),
      });
    }
  }

  if (returnFlightArrivalTime && returnFlightArrivalTimeOptions) {
    const returnFlightArrivalTimeOption = returnFlightArrivalTimeOptions.find(
      option => option.id === returnFlightArrivalTime
    );

    const ANY_VALUE = 'RET_ARR_ANY_TIME';

    if (
      returnFlightArrivalTimeOption &&
      returnFlightArrivalTimeOption.id !== ANY_VALUE
    ) {
      tags.push({
        id: `return-arrival-flight-time-${returnFlightArrivalTimeOption.id}`,
        label: t('returnFlightArrival.time.label', {
          airport: returnFlightArrivalAirport?.iata || '',
          time: returnFlightArrivalTimeOption.caption,
        }),
        nextFiltersState: {
          ...filters,
          returnFlightArrivalTime: null,
          prevChangedFilter: 'returnFlightArrivalTime',
        },
        onRemove: () =>
          trackFilters({
            type: FiltersEventType.FILTERS_REMOVE,
            payload: { label: returnFlightArrivalTimeOption.id },
          }),
      });
    }
  }

  if (radius) {
    const label = radius.label ? radius.label : formatLatLong(radius.center);
    const radiusFilterLabel = `${label}, ${radius.radius / 1000}km`;

    tags.push({
      id: `radius-filter`,
      label: radiusFilterLabel,
      nextFiltersState: {
        ...filters,
        radius: null,
      },
      onRemove: () =>
        trackFilters({
          type: FiltersEventType.FILTERS_REMOVE,
          payload: { label: radiusFilterLabel },
        }),
    });
  }

  return (
    <TagBox
      lines={lines}
      tags={tags.map(tag => ({
        ...tag,
        onRemove: () => {
          onChange(tag.nextFiltersState);
          tag.onRemove();
        },
      }))}
      tagHeight={FILTER_TAG_HEIGHT}
      className={`filter-tags${className ? ` ${className}` : ''}`}
    />
  );
};

export default FilterTags;
