import { useTranslation } from 'next-i18next';
import React, { useRef, useMemo } from 'react';
import {
  AccordionMode,
  Accordion,
} from '@hotelplan/components.common.accordion';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { useTrackFiltersExceptions } from '@hotelplan/libs.tracking';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { FilterOptions } from 'components/domain/filters/Filters.constants';
import FilterTags from 'components/domain/filters/FilterTags';
import {
  ISearchFilterInputProps,
  useRenderFilters,
} from 'components/domain/filters/search-filter-input/blocks/features.utils';
import { FilterCountsStorageProvider } from 'components/domain/filters/search-filter-input/blocks/FilterCounts';
import TotalFilter from 'components/domain/filters/TotalFilter';
import { FeatureList } from 'config/pageConfig.constants';
import {
  FiltersHeaderWrap,
  ResetFiltersButton,
  SearchFilterAccordionWrap,
  SearchFilterTagsWrap,
  SearchFilterWrap,
  ResetFiltersButtonMobile,
} from './SearchFilterInput.styles';

const SearchFilterInput: React.FC<ISearchFilterInputProps> = ({
  onReset,
  skipTotalFiltersRequest = true,
  ...renderProps
}) => {
  const {
    filterCountsLoader,
    filterValues,
    filterOptions,
    filters,
    onChange,
  } = renderProps;

  const { mobile } = useDeviceType();
  const [t] = useTranslation('filters');
  const buttonRef = useRef<HTMLButtonElement>(null);

  const accordionContent = useRenderFilters(renderProps);

  const useTotalCounts = filterCountsLoader.total;

  const { counts, error, loading: loadingFilters } = useTotalCounts({
    skip: skipTotalFiltersRequest,
  });

  useTrackFiltersExceptions(counts?.count, loadingFilters, error);

  const { getVariant } = useFeatureToggle();
  const { name } = getVariant(FeatureList.POPULAR_FILTER_ON_TOP);

  const popularFiltersOpen = filters.map((item, index) =>
    item === 'popularFilters' && name === 'open-on-top' ? index : null
  );

  const handleResetClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    onReset();
    buttonRef?.current?.blur();
  };

  const haveSelectedFilters = useMemo(() => {
    return Boolean(
      Object.keys(FilterOptions)?.find(filter => {
        if (filter === 'flightStopOverDuration') {
          return filterValues[filter]?.maxSelected;
        } else if (filter === 'radius') {
          filterValues[filter]?.radius;
        } else {
          return filterValues[filter]?.length > 0;
        }
      })
    );
  }, [filterValues]);

  return (
    <FilterCountsStorageProvider>
      <SearchFilterWrap>
        <SearchFilterAccordionWrap
          className="search-filters-wrap"
          haveSelectedFilters={haveSelectedFilters}
        >
          <Accordion
            mode={AccordionMode.MULTIPLE}
            defaultActiveIndexes={popularFiltersOpen}
          >
            {accordionContent}
          </Accordion>
        </SearchFilterAccordionWrap>
        <SearchFilterTagsWrap>
          {!mobile && haveSelectedFilters ? (
            <FiltersHeaderWrap>
              <ResetFiltersButton
                ref={buttonRef}
                variant={E_BUTTON_TYPE.LINK_BUTTON}
                data-id="resetFilters"
                onClick={handleResetClick}
              >
                {t('filters:filters.clear')}
              </ResetFiltersButton>
            </FiltersHeaderWrap>
          ) : null}
          {mobile && haveSelectedFilters ? (
            <ResetFiltersButtonMobile
              data-id="resetFilters"
              variant={E_BUTTON_TYPE.LINK_BUTTON}
              onClick={handleResetClick}
            >
              {t('filters.clear')}
            </ResetFiltersButtonMobile>
          ) : null}
          <FilterTags
            lines={mobile ? 2 : 3}
            filters={filterValues}
            options={filterOptions}
            onChange={onChange}
          />
          {mobile && (
            <TotalFilter
              useCounts={filterCountsLoader.total}
              haveSelectedFilters={haveSelectedFilters}
            />
          )}
        </SearchFilterTagsWrap>
      </SearchFilterWrap>
    </FilterCountsStorageProvider>
  );
};

export default SearchFilterInput;
