import { useGoogleMap } from '@react-google-maps/api';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { controlStyles } from './Map.styles';

const MapZoom = styled.div(
  controlStyles,
  sx2CssThemeFn({
    bottom: '10px',
    left: '10px',
    px: 2,
  })
);

const MapCurrentZoomControl: React.FC = () => {
  const [t] = useTranslation('map');
  const map = useGoogleMap();

  const [zoom, setZoom] = useState(3);

  useEffect(
    function zoomChangeEffect() {
      if (!map) return;

      setZoom(map.getZoom());

      const { remove } = map.addListener('zoom_changed', () => {
        setZoom(map.getZoom());
      });

      return () => {
        try {
          remove();
        } catch (e) {}
      };
    },
    [map]
  );

  return <MapZoom>{t('map:map.zoom', { zoom })}</MapZoom>;
};

export default MapCurrentZoomControl;
