import React from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import {
  PinCluster,
  MapPinView,
  MapClusterer,
} from '@hotelplan/components.common.map-pin';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { SrlMapProductPinFragment } from 'graphql/srlMap/SRLMapFragments.generated';
import SRLMapProductPin from './SRLMapProductPin';

const PinContent = styled.div(
  sx2CssThemeFn({
    display: 'flex',
    alignItems: 'center',
    px: 1,
  })
);

const HotelIcon = styled(Icon).attrs({ name: `hotel`, size: 'lg' })(
  sx2CssThemeFn({
    paddingRight: 2,
  })
);

const PinText = styled.span(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn(FONT_SIZE.MEDIUM_BOLD)
);

function renderPinContent(cluster?: PinCluster): React.ReactNode {
  return (
    <PinContent>
      <HotelIcon />
      <PinText>{cluster?.getSize()}</PinText>
    </PinContent>
  );
}

type Product = SrlMapProductPinFragment;

interface ISRLMapProductClusterProps {
  products: Product[];
  onClusterClick(products: Product[], cluster: PinCluster): void;
  onProductClick(product: Product, pin: MapPinView<Product>): void;
  onClickOnSelectedPin?(): void;
}

const SRLMapProductCluster: React.FC<ISRLMapProductClusterProps> = props => {
  const {
    products,
    onProductClick,
    onClusterClick,
    onClickOnSelectedPin,
  } = props;

  function onClusterClickHandler(cluster: PinCluster): void {
    const pins = cluster.getMarkers() as MapPinView<Product>[];
    const items = pins?.map(pin => pin.object);
    onClusterClick(items, cluster);
  }

  return (
    <MapClusterer
      renderPinContent={renderPinContent}
      onClusterClick={onClusterClickHandler}
    >
      {products.map((product, index) => (
        <SRLMapProductPin
          key={`p-${index}`}
          product={product}
          onClick={onProductClick}
          onClickOnSelectedPin={onClickOnSelectedPin}
        />
      ))}
    </MapClusterer>
  );
};

export default SRLMapProductCluster;
