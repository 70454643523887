import React from 'react';
import { AccordionItem } from '@hotelplan/components.common.accordion';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { AuthChannelType } from '@hotelplan/graphql.local-types';
import { formatTestId } from '@hotelplan/libs.utils';
import ExpiredFilter from 'components/domain/filters/ExpiredFilter';
import { useFilterCountErrorsContext } from 'components/domain/filters/FilterCountErrorsContext';
import { FilterOptions as Options } from 'components/domain/filters/Filters.constants';
import type {
  IFiltersFormState,
  IFilterOptions,
  TFilterOptionCaptions,
  TFilterOptionOrderName,
  TFilterCountsLoader,
} from 'components/domain/filters/Filters.types';
import AccommodationSize from 'components/domain/filters/search-filter-input/blocks/AccommodationSize';
import Beach from 'components/domain/filters/search-filter-input/blocks/additional-product-feature/Beach';
import Children from 'components/domain/filters/search-filter-input/blocks/additional-product-feature/Children';
import Culture from 'components/domain/filters/search-filter-input/blocks/additional-product-feature/Culture';
import Distance from 'components/domain/filters/search-filter-input/blocks/additional-product-feature/Distance';
import Environment from 'components/domain/filters/search-filter-input/blocks/additional-product-feature/Environment';
import Hotel from 'components/domain/filters/search-filter-input/blocks/additional-product-feature/Hotel';
import Sport from 'components/domain/filters/search-filter-input/blocks/additional-product-feature/Sport';
import Wellness from 'components/domain/filters/search-filter-input/blocks/additional-product-feature/Wellness';
import Airlines from 'components/domain/filters/search-filter-input/blocks/airlines/Airlines';
import Airports from 'components/domain/filters/search-filter-input/blocks/Airports';
import BoardType from 'components/domain/filters/search-filter-input/blocks/BoardType';
import DepartureArrivalTimes from 'components/domain/filters/search-filter-input/blocks/departure-arrival/DepartureArrivalTimes';
import type { TComponentProps } from 'components/domain/filters/search-filter-input/blocks/features.types';
import FlightOptions from 'components/domain/filters/search-filter-input/blocks/flight-time/FlightOptions';
import FlightDuration from 'components/domain/filters/search-filter-input/blocks/FlightDuration';
import FlightStops from 'components/domain/filters/search-filter-input/blocks/FlightStops';
import Main from 'components/domain/filters/search-filter-input/blocks/Main';
import MaxPricePerPerson from 'components/domain/filters/search-filter-input/blocks/MaxPricePerPerson';
import Popular from 'components/domain/filters/search-filter-input/blocks/Popular';
import Ratings from 'components/domain/filters/search-filter-input/blocks/ratings/Ratings';
import RoomType from 'components/domain/filters/search-filter-input/blocks/RoomType';
import StopDuration from 'components/domain/filters/search-filter-input/blocks/stop-duration/StopDuration';
import withViewportLoad from 'components/domain/filters/search-filter-input/blocks/withViewportLoad';

export interface IFilterElement {
  testId?: string;
  component?: React.FC<TComponentProps>;
  isB2B?: boolean;
}

export interface ILabeledFilterElement extends IFilterElement {
  label: string;
}

export interface ISearchFilterInputProps {
  filters: TFilterOptionOrderName[];
  filterValues: IFiltersFormState;
  filterOptions: IFilterOptions;
  filterOptionCaptions: TFilterOptionCaptions;
  onChange: (nextValue: IFiltersFormState) => void;
  onReset?: () => void;
  onCloseModal?: () => void;
  filterCountsLoader: TFilterCountsLoader;
  expandAllFilters?: boolean;
  skipTotalFiltersRequest?: boolean;
}

export const filtersDict: {
  [key in TFilterOptionOrderName]?: IFilterElement | null;
} = {
  roomTypes: {
    testId: 'Accommodation_types',
    component: withViewportLoad(RoomType),
  },
  accommodationSize: {
    testId: Options.accommodationSize,
    component: withViewportLoad(AccommodationSize),
    isB2B: true,
  },
  boardTypes: { testId: 'Board_types', component: withViewportLoad(BoardType) },
  ratings: { testId: 'Rating', component: withViewportLoad(Ratings) },
  maxPricePerPerson: {
    testId: 'Price',
    component: withViewportLoad(MaxPricePerPerson),
  },
  departureAirports: {
    testId: Options.flightAirlines,
    component: withViewportLoad(Airports),
  },
  flightOptions: {
    testId: 'Flight_Options',
    component: withViewportLoad(FlightOptions),
  },
  flightStopOver: {
    testId: 'Stopovers',
    component: withViewportLoad(FlightStops),
  },
  mainFeatures: {
    testId: Options.mainFeatures,
    component: withViewportLoad(Main),
  },
  childrenFeatures: {
    testId: Options.childrenFeatures,
    component: withViewportLoad(Children),
  },
  beachFeatures: {
    testId: Options.beachFeatures,
    component: withViewportLoad(Beach),
  },
  distanceFeatures: {
    testId: Options.distanceFeatures,
    component: withViewportLoad(Distance),
  },
  sportFeatures: {
    testId: Options.sportFeatures,
    component: withViewportLoad(Sport),
  },
  wellnessFeatures: {
    testId: Options.wellnessFeatures,
    component: withViewportLoad(Wellness),
  },
  hotelFeatures: {
    testId: Options.hotelFeatures,
    component: withViewportLoad(Hotel),
  },
  environmentFeatures: {
    testId: Options.environmentFeatures,
    component: withViewportLoad(Environment),
  },
  cultureFeatures: {
    testId: Options.cultureFeatures,
    component: withViewportLoad(Culture),
  },
  popularFilters: {
    testId: Options.popularFilters,
    component: withViewportLoad(Popular),
  },
  flightAirlines: {
    testId: Options.flightAirlines,
    component: withViewportLoad(Airlines),
  },
  maxFlightDuration: {
    testId: Options.maxFlightDuration,
    component: withViewportLoad(FlightDuration),
  },
  flightStopOverDuration: {
    testId: Options.flightStopOverDuration,
    component: withViewportLoad(StopDuration),
  },
  flightDepartureArrivalTimes: {
    testId: 'flightDepartureArrivalTimes',
    component: withViewportLoad(DepartureArrivalTimes),
  },
};

const findFilters = ({ filters, filterOptionCaptions }) => {
  return filters
    .map(id => {
      const filter = filtersDict[id];

      if (filter) return { ...filter, label: filterOptionCaptions[id] };
      return null;
    })
    .filter((item): item is ILabeledFilterElement => item !== null);
};

export type TRenderFiltersProps = {
  filters: TFilterOptionOrderName[];
  filterValues: IFiltersFormState;
  filterOptions: IFilterOptions;
  filterOptionCaptions: TFilterOptionCaptions;
  onChange: (nextValue: IFiltersFormState) => void;
  onCloseModal?: () => void;
  filterCountsLoader: TFilterCountsLoader;
};

export const useRenderFilters = ({
  filters,
  filterOptionCaptions,
  onCloseModal,
  filterValues,
  onChange,
  filterCountsLoader,
  filterOptions,
}: TRenderFiltersProps) => {
  const { withErrors } = useFilterCountErrorsContext();
  const { channelType } = useAuthentication();
  const isB2BAuthenticated = channelType === AuthChannelType.B2B;

  return findFilters({ filters, filterOptionCaptions })
    .map((filter, index) => {
      if (filter.isB2B && !isB2BAuthenticated) return null;

      return (
        <AccordionItem
          title={filter.label}
          key={index}
          testId={formatTestId(filter.testId || '')}
        >
          {active => {
            if (!active) return null;
            if (withErrors)
              return <ExpiredFilter onCloseModal={onCloseModal} />;

            if (filter.component) {
              return (
                <filter.component
                  values={filterValues}
                  options={filterOptions}
                  onChange={onChange}
                  countLoaders={filterCountsLoader}
                />
              );
            }

            return null;
          }}
        </AccordionItem>
      );
    })
    .filter(Boolean);
};
