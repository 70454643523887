import type { QueryResult, QueryHookOptions } from '@apollo/client';
import isEqual from 'fast-deep-equal';
import noop from 'lodash/noop';
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { isBrowser } from '@hotelplan/libs.utils';
import { shouldRecalculateCountsWithinFilter } from 'components/domain/filters/Filters.mappers';
import type {
  TUseCounts,
  TFilterOptionName,
  TRecalculatedFilterOptionName,
  TFilterCountsStorage,
} from 'components/domain/filters/Filters.types';
import { TUseCountsOptions } from 'components/domain/filters/Filters.types';

type TSaveCounts = (
  filter: keyof TFilterCountsStorage,
  value: TFilterCountsStorage[keyof TFilterCountsStorage]
) => void;

interface IFilterCountsStorageContext {
  countsStorage: TFilterCountsStorage;
  saveCounts: TSaveCounts;
}

export const FilterCountsStorageContext = React.createContext<IFilterCountsStorageContext>(
  {
    countsStorage: {},
    saveCounts: noop,
  }
);

export const FilterCountsStorageProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [state, setState] = useState<TFilterCountsStorage>({});
  const saveCounts: TSaveCounts = (filter, value) => {
    setState(prevState => ({ ...prevState, [filter]: value }));
  };
  return (
    <FilterCountsStorageContext.Provider
      value={{ countsStorage: state, saveCounts }}
    >
      {children}
    </FilterCountsStorageContext.Provider>
  );
};

export const useFilterCountsStorageContext = () => {
  return useContext(FilterCountsStorageContext);
};

export type TUsePrepareValuesForCountsRequest<TCountsQueryVariables> = () => {
  countsQueryVariables: TCountsQueryVariables;
  prevChangedFilter: TFilterOptionName | null | undefined;
};

export const FORCE_RECALCULATE_COUNTS = 'forceRecalculateCounts';
const prevCountsQueryVariables = {};

export const countsHooksFactory = <TQueryVariables,>(
  usePrepareValuesForCountsRequest: TUsePrepareValuesForCountsRequest<TQueryVariables>
) => <
  TQuery extends (options: QueryHookOptions<any, any>) => QueryResult,
  TMapper extends (val: QueryResult['data'] | undefined) => any
>(
  useCounts: TQuery,
  mapper: TMapper,
  filter: TFilterOptionName | 'total'
): TUseCounts<NonNullable<ReturnType<TMapper>>> => (
  { skip, onCompleted }: TUseCountsOptions = {
    skip: false,
  }
) => {
  const {
    countsQueryVariables: variables,
    prevChangedFilter,
  } = usePrepareValuesForCountsRequest();
  const { type } = useDeviceType();

  // NOTE: this state is needed because when query is skipped
  // apollo sets its data to undefined (it means that our counts will be zeros)
  // we don't need it (we should show prev counts), so the intermediate state should do the trick.
  // Data will be reset only when onComplete is executed (when skip === false)
  const { countsStorage, saveCounts } = useFilterCountsStorageContext();

  const prevCounts = countsStorage[filter];

  const { data } = useCounts({
    fetchPolicy: 'cache-only',
    ssr: false,
    variables: variables,
  });

  const isAlreadyRequested =
    data &&
    isEqual(prevCountsQueryVariables[type]?.[filter], variables) &&
    !!countsStorage[filter];

  const shouldRecalculate = useMemo(() => {
    return (
      filter === 'total' ||
      shouldRecalculateCountsWithinFilter(
        prevChangedFilter as TRecalculatedFilterOptionName,
        filter as TRecalculatedFilterOptionName,
        prevCounts
      )
    );
  }, [filter, prevChangedFilter, prevCounts]);
  const [forceRecalculate, setForceRecalculate] = useState(false);

  const skipRequest =
    skip ||
    isAlreadyRequested ||
    (forceRecalculate ? false : !shouldRecalculate);

  if (!skipRequest) {
    prevCountsQueryVariables[type] = {
      ...prevCountsQueryVariables[type],
      [filter]: variables,
    };
  }

  const { loading, error, data: currentData } = useCounts({
    // NOTE: cache-and-network because of strange cache behavior
    // cache does not store all counts of all possible filter values
    // instead it stores only latest counts and it causes bug when the user
    // unchecks the filter but counts are not updating because of cache
    fetchPolicy: 'cache-and-network',
    ssr: false,
    skip: skipRequest,
    variables: variables,
  });

  const actualCounts = currentData || data;

  useEffect(() => {
    if (skipRequest) return;

    saveCounts(filter, mapper(actualCounts));
    setForceRecalculate(false);
    onCompleted && onCompleted(actualCounts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipRequest, actualCounts]);

  useEffect(() => {
    const handler = () => {
      if (filter === 'total') return;

      setForceRecalculate(true);
    };

    window.addEventListener(FORCE_RECALCULATE_COUNTS, handler);

    return (): void =>
      window.removeEventListener(FORCE_RECALCULATE_COUNTS, handler);
  }, []);

  return {
    counts: countsStorage[filter] as any,
    loading,
    error: !!error,
  };
};

const forceRecalculateCountsEvent = isBrowser
  ? new CustomEvent(FORCE_RECALCULATE_COUNTS)
  : null;

export const forceRecalculateCounts = () => {
  forceRecalculateCountsEvent &&
    window.dispatchEvent(forceRecalculateCountsEvent);
};
